/** @format */

@import "../../sass/abstracts/_Variable.scss";

.header-main-wrap {
  position: relative;
  &.no-banner {
    min-height: 160px;

    @media (max-width: 991px) {
      min-height: 101px;
    }

    @media (max-width: 575px) {
      min-height: 84px;
    }

    .header-inner {
      position: static;
      background-color: #063553;
    }
  }

  .header-inner {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    // padding: 25px 30px;
    padding-top: 5px;
    padding-bottom: 5px;
    transition: all 0.25s linear;
    z-index: 99;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    .serach-bar-menu {
      margin: auto;
      position: absolute;
      //   right: 0;
      top: 83px;
      left: 25%;
      width: 50%;
      //   width: calc(100vw - 40px);
      //   height: 20vh;
      background-color: #ffffff;
      padding: 10px 10px;
      z-index: 99;
      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      border-radius: 20px;
      @media (max-width: 1100px) {
        top: 100px;
      }
      @media (max-width: 992px) {
        top: 70px;
      }
      @media (max-width: 574px) {
        top: 83px;
      }
      @media (max-width: 992px) {
        width: 80%;
        left: 10%;
      }
      @media (max-width: 380px) {
        width: 90%;
        left: 5%;
      }

      form {
        // display: flex;
        justify-content: center;
        display: flex;
        flex-direction: row;
        align-items: center;
        // gap: 20px;
        @media (max-width: 768px) {
          flex-direction: column;
          gap: 10px;
        }

        .select-input {
          width: 30%;
          @media (max-width: 768px) {
            width: 100%;
          }
        }
        .search-input {
          width: 40%;
          display: flex;
          align-items: center;
          @media (max-width: 768px) {
            width: 100%;
          }

          input {
            width: 100%;
          }
        }

        @media (max-width: 767px) {
          flex-direction: column;
        }
      }

      .input {
        border: 1px solid #cac7c7;
        color: rgb(29, 29, 29);
        background-color: transparent;
        min-height: auto;
        font-size: 14px;
        padding: 5px 5px;
        width: 100%;
        @media (max-width: 767px) {
          margin-bottom: 5px;
        }

        option {
          color: #000;
        }

        &:not(:first-child) {
          @media (min-width: 767px) {
            border-left: 0;
          }
        }

        &::placeholder {
          color: #fff;
          opacity: 0.5;
        }
      }

      input {
        min-width: 40%;
        background-color: transparent;
        padding: 0 8px;
        color: rgb(29, 29, 29);
        border: 1px solid #cac7c7;
        min-height: 30px;

        &::placeholder {
          color: #747272;
        }

        &:focus {
          // border: none;
          outline: none;
          // border-bottom: 1px solid #ccc;
        }
      }

      .cm-button {
        border: none;
        outline: none;
        background-color: $footer-copyright-bg-color;
        padding: 5px 10px;
        min-height: 30px;

        svg {
          transition: all 0.2s linear;
          // font-size: 16px;
          color: #ffffff;
        }

        &:hover {
          background-color: $header-search-btn-hover-bg-color;

          svg {
            color: $header-search-btn-hover-color;
          }
        }
      }
    }

    @media (max-width: 991px) {
      display: flex;
      justify-content: space-between;
      width: 100%;
      align-items: center;
    }

    @media (max-width: 575px) {
      padding: 15px;
    }

    &.clearfix {
      @media (max-width: 991px) {
        &:after {
          display: none;
        }

        &::before {
          display: none;
        }
      }
    }

    .logo-wrap {
      @media (min-width: 992px) {
        float: left;
        width: 17%;
        position: relative;
        top: 0;
      }

      @media (max-width: 991px) {
      }

      img {
        max-width: 85px;
        width: 100%;
        transition: all 0.25s linear;

        @media (max-width: 991px) {
          max-width: 80px;
        }

        @media (max-width: 575px) {
          max-width: 70px;
        }
      }
    }

    .desktop-menu-wrap {
      @media (min-width: 992px) {
        float: left;
        // width: 60%;

        padding: 0 20px;
        text-align: center;
      }

      @media (max-width: 991px) {
        position: fixed;
        width: 100%;
        left: auto;
        right: -100%;
        background: #063553;
        height: 100vh;
        top: 91px;
        padding-top: 20px;
        transition: all 0.3s linear;
        padding-left: 10px;
        padding-right: 10px;
        border-top: 2px solid #fff;
        z-index: 9;
      }

      @media (max-width: 575px) {
        top: 82px;
      }

      .search-wrap {
        @media (max-width: 991px) {
        }

        form {
          // display: flex;
          justify-content: center;
          display: flex;
          flex-direction: row;
          align-items: center;

          @media (max-width: 767px) {
            flex-direction: column;
          }
        }

        .input {
          border: 1px solid $header-input-border-color;
          color: $header-input-color;
          background-color: transparent;
          min-height: auto;
          font-size: 14px;
          padding: 5px 5px;
          max-width: 150px;

          @media (max-width: 767px) {
            margin-bottom: 5px;
          }

          option {
            color: #000;
          }

          &:not(:first-child) {
            @media (min-width: 767px) {
              border-left: 0;
            }
          }

          &::placeholder {
            color: #fff;
            opacity: 0.5;
          }
        }

        input {
          min-width: 40%;
          background-color: transparent;
          padding: 0 8px;
          color: #ffffff;
          border: 1px solid rgb(255, 255, 255);
          min-height: 31px;

          &::placeholder {
            color: #ffffff;
          }

          &:focus {
            // border: none;
            outline: none;
            // border-bottom: 1px solid #ccc;
          }
        }

        .cm-button {
          border: none;
          outline: none;
          // background-color: $header-search-btn-bg-color;
          padding: 6px;

          svg {
            transition: all 0.2s linear;
            // font-size: 16px;
            // color: $header-search-btn-color;
          }

          &:hover {
            background-color: $header-search-btn-hover-bg-color;

            svg {
              color: $header-search-btn-hover-color;
            }
          }
        }
      }

      .menu-wrap {
        margin-top: 20px;

        @media (max-width: 991px) {
        }

        .level-1 {
          display: flex;
          justify-content: center;
          flex-wrap: nowrap;
          align-items: center;

          @media (max-width: 991px) {
            flex-direction: column;
            align-items: flex-start;
          }
        }

        .menu-item {
          padding: 10px 12px;
          position: relative;
          transition: 0.3s;
          color: red !important;

          .active-class {
            color: #cdaf73;
            width: 100%;
            // &:after {
            //
            // }
          }

          .menu-link {
            // color: $header-anchor-color;
            font-weight: 500;

            .navigation-menu {
              display: flex;
              flex-direction: row;
              gap: 5px;
            }

            // &:hover {
            //     color: $header-anchor-hover-color;

            //
            // }

            &.active {
              color: $header-anchor-active-color;

              &:after {
                width: 100%;
                border-bottom-color: $header-active-border-color;
              }
            }

            &:after {
              content: "";
              width: 0;
              position: relative;
              border-bottom: 2px solid $header-link-border-color;
              top: 1px;
              display: block;
              transition: all 0.25s ease-in-out;
            }
          }

          :hover {
            .level-2 {
              display: block;
            }
          }

          .level-2 {
            display: none;
            position: absolute;
            top: 30px;
            left: 0;
            min-width: 200px;
            left: 10px;
            text-align: left;
            padding-top: 10px;
            background-color: #144162c7;
            border-radius: 3px;
            box-shadow: #0000004d 0px 19px 38px, #00000038 0px 15px 12px;
          }

          .mega-submenu {
            position: fixed;
            // display: none;
            margin-top: 10px;
            right: 0;
            width: 90%;

            // height: 60vh;
            z-index: 99;
            margin-right: 5%;
            border-radius: 20px;
            @media (max-width: 991px) {
              display: none;
            }

            .transparent-div {
              height: 10px;
              width: 100%;
              background-color: transparent;
            }

            .submenu-ul {
              align-items: self-start;
              display: flex;
              flex-direction: row;
              justify-content: space-between;
              flex-wrap: wrap;
              gap: 20px;
              padding: 30px;
              text-align: left;
              background: #ffffff;
              box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
              border-radius: 20px;

              li {
                color: #063553;
                width: 20%;
                cursor: pointer;
                overflow: hidden;
                // word-wrap: break-word;
                font-weight: 500;
                transition: 0.3s;

                &:hover {
                  color: #cdaf73;
                }
              }
            }
          }
        }

        li.sell-on-btn {
          margin-left: 10px;

          > a {
            background-size: 400%;
            border-radius: 30px;
            padding: 7px 20px;
            line-height: 1;
            display: inline-block;
            color: #000;
            font-weight: 500;
            position: relative;
            z-index: 1;
            animation: animate 11s linear infinite;
            color: #fff;
          }
        }
      }
    }

    .left-col-wrap {
      @media (min-width: 992px) {
        float: right;
        display: flex;
        flex-wrap: nowrap;
        width: 20%;
        justify-content: flex-end;
        position: relative;
        padding-top: 10px;
      }

      @media (max-width: 991px) {
        display: flex;
        align-items: center;
      }

      .left-menu-item {
        display: inline-block;
        color: $header-anchor-color;

        &.register svg {
          font-size: 18px;
        }

        &:not(:last-child) {
          margin-right: 20px;
        }

        .lm-inner {
          display: flex;
          align-items: center;
          // font-size: 16px;

          .search-bar-icon {
            margin: 0 20px 0 0;
            position: relative;
          }

          img {
            min-width: 30px;
          }

          .userName {
            svg {
              margin-right: 5px;
            }

            text-transform: capitalize;
          }

          a {
            position: relative;
            font-size: 18px;

            span {
              position: absolute;
              top: 33px;
              font-size: 14px;
              opacity: 0;
              visibility: hidden;
              transition: all 0.3s ease;
              width: 100px;
              color: #fff;
              text-align: center;
              left: -30px;

              &.cart_count {
                visibility: visible;
                opacity: 1;
                top: -20px;
                left: 50%;
                transform: translateX(-50%);
                // background: red;
                border-radius: 50%;
                height: auto;
                width: auto;
                padding: 5px 4px;
                line-height: 1;
                text-align: center;
              }
            }

            &:hover {
              span {
                visibility: visible;
                opacity: 1;
              }
            }

            svg {
              font-size: 24px;
            }
          }
        }

        a {
          color: $header-anchor-color;
          display: flex;
          height: 100%;
          align-items: center;
          flex-wrap: wrap;

          @media (max-width: 575px) {
            flex-direction: column;
          }

          &:hover {
            color: $header-anchor-hover-color;
          }

          svg {
            margin-right: 5px;
          }
        }
      }

      .mobile_icon {
        display: none;

        @media (max-width: 991px) {
          width: 35px;
          font-size: 25px;
          color: #fff;
          padding: 0 5px;
          display: flex;
          z-index: 99;
        }

        span {
          transition: all 0.3s linear;
          cursor: pointer;

          &.close {
            display: none;
          }
        }
      }

      .shipping_cart {
        position: absolute;
        background: #fff;
        padding: 20px;
        box-shadow: 1px 0 7px 1px #6767672b;
        min-width: 390px;
        top: 100%;
        left: auto;
        right: 0;
        overflow-y: auto;
        margin-top: 10px;
        opacity: 0;
        transition: opacity 0.3s ease-in-out;
        visibility: hidden;
        z-index: 10;
        box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
          rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px,
          rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
        border-radius: 5px;

        @media (max-width: 767px) {
          width: 100%;
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          height: 100vh;
        }

        .shipping_cart_inner {
          width: 100%;
          position: relative;
          height: 300px;

          .cart_ttile {
            font-size: 20px;

            h4 {
              font-size: inherit;
            }

            span {
              position: absolute;
              top: 0;
              right: 0;
              color: #aaa;
              cursor: pointer;

              &:hover {
                color: #063553;
              }
            }
          }

          .cart_item {
            display: flex;
            justify-content: space-between;
            padding: 10px 0;
            align-items: center;
            color: #063553;
            border-bottom: 1px solid rgba(0, 0, 0, 0.125);

            .image {
              width: 100%;
              text-align: center;
              max-width: 50px;
              margin-right: 10px;
            }

            .discription {
              width: 100%;
              padding: 0 10px;

              h5 {
                font-size: 16px;
                margin-bottom: 0;
              }

              del {
                display: block;
                color: #063553;
                padding-bottom: 5px;
              }
            }

            .delet {
              width: 100%;
              text-align: center;
              max-width: 50px;
              cursor: pointer;
            }

            span {
              &.price {
                font-weight: bold;
              }

              &.qty {
                font-size: 10px;
                margin-left: 5px;
                font-style: italic;
              }
            }

            .qty {
              margin: 10px 0;

              span {
                display: inline-block;
                padding: 2px 6px;
                border: 1px solid #e4e5e5;
                cursor: pointer;
                font-weight: 700;
                color: #005195;
                background-color: #f3f4f6;
                transition: background-color 0.15s ease-in-out;

                &.num {
                  cursor: default;
                }
              }
            }
          }
        }

        .button_wrap {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          align-items: flex-start;
          padding-left: 20px;
          padding-right: 20px;
          text-align: center;
          align-items: center;
          margin-top: 25px;
          color: #005195;
          padding-bottom: 15px;

          h6 {
            margin: 0;
            color: #005195;
          }

          a {
            background-color: #005195;
            color: #fff;
            padding: 8px 15px 6px;
            border-radius: 3px;
            border: 1px solid #005195;
            transition: all 0.3s linear;

            &:hover {
              color: #005195;
              background-color: transparent;
              font-weight: bold;
              //   border-width: 2px;
            }
          }
        }

        &.show {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }

  &.mobile_open {
    .header-inner {
      background: #063553;
    }

    .mobile_icon {
      span {
        color: #fff;

        &.open {
          display: none;
        }

        &.close {
          display: block !important;
          opacity: 1;
        }
      }
    }

    .desktop-menu-wrap {
      right: 0;
    }
  }
}

body.scroll {
  .header-inner {
    background-color: #063553;
    box-shadow: 0px 0px 9px 5px #00000054;

    @media (min-width: 992px) {
      background-color: #063553f5;
      //   padding-top: 20px;
      padding-bottom: 10px;
    }

    .logo-wrap {
      img {
        @media (min-width: 992px) {
          max-width: 80px;
          top: 0px;
        }
      }
    }

    // .desktop-menu-wrap {
    //   .menu-wrap {
    //     margin-top: 10px;
    //   }
    // }
  }

  .header-main-wrap.no-banner .header-inner {
    position: fixed;
    background-color: #063553f5;
    box-shadow: 0px 0px 9px 5px #00000054;
    padding-top: 20px;
    padding-bottom: 10px;
  }
}

@keyframes animate {
  0% {
    background-position: 0%;
  }

  100% {
    background-position: 400%;
  }
}
