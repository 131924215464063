.related-product-wrap {
  background-color: #efefef;
  margin-top: 30px;
  padding-bottom: 50px;
  padding-top: 50px;

  .rpw-inner {
    .item {
      .item-inner {
        padding: 20px 20px;
        border: 1px solid #c9bebe;
        margin: 10px;
        .img-wrap{
          display:block;
          width: 100%;
          text-align: center;
        }
        img {
          margin: 0 auto;
          max-width: 256px;
          height: 160px;
        }

        @media (max-width: 575px) {
          text-align: center;
        }

        .slider3-img {
        
            text-align: center;
            min-height: 200px;
       
          img {
            margin: 0 auto;
            max-width: 256px;
            height: 160px;
          }
        }

        .content {
          p {
            margin: 0;
            font-size: 12px;

            .saleprice {
              font-size: 14px;
              margin-right: 10px;
              color: #47c09a;
            }

            .regular-price {
              font-size: 12px;
              color: #ff0000ad;
              text-decoration: line-through;
            }
          }
        }

      }
    }
  }
}