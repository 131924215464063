body {
    font-size: $body-fontSize;
    color: $body-color;
    line-height: $body-lineHeight;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $body-family;
}

img {
    max-width: 100%;
    display: inline-block;
    vertical-align: middle;
}

.clearfix {
    &:after {
        content: '';
        display: table;
        clear: both;
    }

    &:before {
        content: '';
        display: table;
        clear: both;
    }

}

h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
    font-weight: map-get($font-weights, bold);
    line-height: $heading-lineHeight;
    color: $heading-color;
}

a {
    color: $anchor-color;
    display: inline-block;
    text-decoration: none;

    &:hover {
        color: $anchor-hover-color;
        text-decoration: none;
    }
}

.menu-link {
    color: $header-anchor-color;
    font-weight: 500;

    &:after {
        width: 100%;
    }
}

.input {
    padding: 10px 15px;
    font-size: inherit;
    line-height: inherit;
    font-family: inherit;
    color: inherit;
    line-height: inherit;

    &:focus {
        outline: none;
    }
}