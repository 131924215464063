$body-color: #000;
$body-fontSize: 14px;
$body-lineHeight: 1.35;
$body-family: "Roboto",-apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
  "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
$anchor-color: #000;
$anchor-hover-color: #cdaf73;

$h1-size: 30px;
$h2-size: 28px;
$h3-size: 26px;
$h4-size: 24px;
$h5-size: 20px;
$h1-size: 14px;
$test-color:red;

$heading-color: #000;
$heading-lineHeight: 1.5;

$font-weights: (
  "light": 300,
  "regular": 400,
  "bold": 500,
  "bolder": 700,
);


// ========= Header=========
$header-link-color:#fff;
$header-anchor-color:#fff;
$header-anchor-hover-color:#cdaf73;
$header-anchor-active-color:#cdaf73;
$header-link-border-color:#cdaf73;
$header-active-border-color:#cdaf73;
$header-input-border-color:#fff;
$header-input-color:#fff;
$header-search-btn-color:#000;
$header-search-btn-hover-color:#fff;
$header-search-btn-bg-color:#fff;
$header-search-btn-hover-bg-color:#cdaf73;

// ========= Home Banner========
$banner1-btn-color:#000;
$banner1-btn-bg-color:#fff;
$banner1-btn-border-radius:5px;
$banner1-btn-border-width:1px;
$banner1-btn-border-color:#000;
$banner1-btn-vertical-padding:8px;
$banner1-btn-horizontal-padding:15px;
$banner1-btn-border-style:solid;

// ==========Footer=========
$footer-copyright-bg-color:#063553;
$footer-copyright-color:#fff;



$tablet-Size:991px;