.product-detail-wrap {
  .product-detail-inner {
    .breadCum-wrap {
      margin-top: 15px;
      margin-bottom: 25px;

      ul {
        margin: 0;
        padding: 0;
        font-size: 14px;
        font-weight: 500;

        li {
          display: inline-block;

          span {
            margin-left: 5px;
            margin-right: 5px;
          }
        }
      }
    }

    .product-section {
      .product-section-inner {
        display: flex;
        flex-wrap: wrap;

        .p-right {
          width: 50%;

          @media (max-width: 767px) {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
          }

          .pc-gallery-wrap {
            max-width: 75px;
            float: left;
            margin-top: 20px;
            margin-bottom: 20px;
            width: 100%;
            width: 50%;

            .slick-track {
              height: auto !important;
            }

            img {
              cursor: pointer;
            }

            @media (max-width: 767px) {
              max-width: 400px;
              max-height: 75px;
              float: none;
              width: 100%;
              margin: 0 auto;
              text-align: center;
              order: 2;
              margin-bottom: 70px;

              img {
              }
            }

            .pc-gallery-item:not(:last-child) {
              margin-bottom: 10px;
            }

            .slick-slide {
              & > div {
                padding: 10px;
              }
            }

            .slick-next {
              right: 26px;
              top: -24px;
              transform: rotate(270deg);

              @media (max-width: 767px) {
                right: -11px;
                top: 50%;
                transform: translateY(-50%);
              }
            }

            .slick-prev {
              right: auto;
              bottom: -24px;
              left: 26px;
              top: auto;
              transform: rotate(270deg);

              @media (max-width: 767px) {
                right: auto;
                left: -11px;
                top: 50%;
                transform: translateY(-50%);
              }
            }
          }

          .pc-main-image-wrap {
            max-width: calc(100% - 75px);
            float: left;
            padding: 20px;
            position: relative;
            width: 100%;
            padding-top: 0;

            @media (max-width: 767px) {
              text-align: center;
              max-width: unset;
              display: block;
              float: none;
              width: 100%;
              margin: 0 auto;
              text-align: center;
              order: 1;

              img {
                margin: 0 auto;
                text-align: center;
                max-width: 250px;
              }
            }

            img {
              max-width: 400px;
              max-height: 450px;
              min-width: 250px;
              width: 100%;
            }

            .discount-wrap {
              position: absolute;
              top: 25px;
              left: 27px;
              width: 50px;
              height: 50px;
              background-color: #cdaf73;
              color: #fff;
              border-radius: 51%;
              font-size: 15px;

              @media (max-width: 767px) {
                z-index: 9;
                top: 11px;
                left: 5%px;
              }

              span {
                top: 50%;
                left: 50%;
                position: relative;
                transform: translate(-50%, -50%);
                display: inline-block;

                @media (max-width: 767px) {
                  left: 28%;
                }
              }
            }
          }
        }

        .p-left {
          width: 50%;

          @media (max-width: 767px) {
            width: 100%;
          }

          .pc-title {
            h1 {
              font-size: 26px;
              margin: 0;
            }

            p {
              margin: 0;
              padding: 0;

              a {
                color: #cdaf73;
                text-decoration: none;
              }
            }
          }

          .pc-review {
            margin: 0;
            font-size: 12px;
            margin-bottom: 5px;

            & > span {
              display: inline-block;
              margin-right: 8px;

              &.star-icon {
                .react-stars {
                  span {
                    font-size: 20px !important;
                    line-height: 1;
                    top: 3px !important;
                  }
                }
              }
            }
          }

          .pc-price {
            margin-top: 10px;

            p {
              padding: 0;
              margin: 0;

              .regular-price {
                font-size: 20px;
                color: #ff0000ad;
                text-decoration: line-through;
              }

              .saleprice {
                font-size: 30px;
                margin-right: 10px;
                color: #47c09a;
              }
            }
          }

          .pc-desc {
            margin-top: 8px;
            font-size: 12px;
            max-height: 57px;
            overflow: hidden;
            margin-bottom: 5px;

            p {
              margin: 0;
            }
          }

          .pc-stock-status {
            margin-top: 0px;

            span {
              font-weight: 500;
              font-size: 15px;
              color: #3e9322;
              &.out-of-stock {
                color: red;
              }
            }
          }

          .pc-actions {
            .qty-actions {
              span {
                display: inline-flex;
                width: 30px;
                border: 1px solid #000;
                height: 35px;
                justify-content: center;
                align-items: center;
                font-size: 20px;
                position: relative;
                top: 2px;
                &.qty-btn{
                  cursor: pointer!important;
                }

                input {
                  width: 100%;
                  border: none;
                }

                &:hover {
                  color: #cdaf73;
                }

               

                &:not(:last-child) {
                  border-right: 0;
                }
              }
            }

            & > div {
              float: left;
              margin-right: 30px;
            }

            &.btn-wrap {
              margin-top: 15px;
            }
          }

          .add-to-cart {
            .btn-black,
            .btn-brown {
              font-size: 18px;
              font-weight: 400;
              padding: 7px 25px;
              border-width: 1px;
              border-style: solid;
              border-radius: 0;
              &.outofstock {
                background-color: #f60000ba;
                border-color: #f60000ba;
              }
            }
          }

          .add-to-wishlist {
            font-size: 25px;
            font-weight: 300;
            cursor: pointer;

            &:hover {
              svg {
                color: #cdaf73;
              }
            }
          }

          .check-delivery {
            margin-top: 20px;

            p {
              margin: 0;
            }

            form {
              width: 100%;
              position: relative;

              .input {
                width: 100%;
                min-height: 35px;
                padding: 8px;

                &:focus {
                  outline: none;
                  border: 1px solid #cdaf73;
                  box-shadow: none;
                }
              }

              button {
                position: absolute;
                top: 0;
                right: -0px;
                background-color: #9e9e9e1c;
                border: 0;
                height: 100%;
                width: 50px;
                font-size: 20px;
                font-weight: 700;

                &:hover {
                  color: #cdaf73;
                }
              }
            }
          }

          .product-meta {
            margin-top: 8px;
            padding-top: 10px;
            border-top: 1px solid #80808047;

            p {
              margin-bottom: 2px;

              span {
                font-weight: 500;
              }
            }
          }

          .social-share {
            margin-top: 20px;

            .social-item {
              display: inline-block;
              padding: 3px 10px;
              background-color: #1877f2;
              font-size: 12px;
              border-radius: 2px;
              color: #fff;
              transition: all 0.15s linear;

              &.twitter {
                background-color: #2ba5da;
              }

              &.telegram {
                background-color: #28a4e3;
              }

              &.whatsapp {
                background-color: #00e676;
              }

              &:not(:last-child) {
                margin-right: 10px;
              }

              &:hover {
                opacity: 0.8;
              }

              svg {
                margin-right: 5px;
              }
            }
          }
        }
      }
    }

    .frequently-brought-wrap {
      background-color: #efefef;
      margin-top: 30px;

      .fbw-inner {
        padding-top: 40px;
        padding-bottom: 40px;

        .fbw-content {
          width: 75%;
          float: left;

          .fbw-items {
            float: left;
            width: 100%;
            margin-top: 20px;

            .fbw-item {
              position: relative;
              float: left;
              margin-right: 20px;

              &.fbw-btn {
                button {
                  font-size: 14px;
                  font-weight: 400;
                  padding: 5px 15px;
                  border-width: 1px;
                  border-style: solid;
                }
              }

              img {
                max-width: 100px;
                width: 100%;
              }

              .pc-price {
                text-align: center;

                p {
                  padding: 0;
                  margin: 0;

                  .regular-price {
                    font-size: 10px;
                    color: #ee7171d1;
                    text-decoration: line-through;
                  }

                  .saleprice {
                    font-size: 12px;
                    margin-right: 5px;
                    color: #47c09a;
                  }
                }
              }

              input[type="checkbox"] {
                display: none;

                &:checked + .checkbox-label {
                  opacity: 0.6;
                }
              }

              .checkbox-label {
                opacity: 1;
                cursor: pointer;
              }
            }
          }
        }

        .fbw-adv {
          float: right;
          width: 25%;

          .fbw-item {
            img {
              max-width: 300px;
              width: 100%;
            }
          }

          .slick-prev,
          .slick-next {
            top: -14px;
            right: 0;
            left: auto;
            height: 16px;
            width: 20px;
          }

          .slick-prev {
            right: 20px;
          }
        }
      }
    }

    .product-tabber {
      padding-top: 50px;
      padding-bottom: 50px;

      .product-tabber-inner {
        .nav-item {
          button {
            border-radius: 0;
            border: 1px solid rgb(229, 240, 252) 0;
            font-size: 16px;
            padding-top: 10px;
            padding-bottom: 10px;
          }

          &:not(:last-child) {
            button {
              border-right: 0;
            }
          }
        }

        .tab-content {
          .review-inner {
            .title {
              h2 {
                line-height: 1.2;
                margin-bottom: 0px;
              }
            }

            .review-summary-wrap {
              display: flex;
              width: 100%;
              flex-wrap: wrap;

              .review-wrap {
                width: 50%;

                @media (max-width: 991px) {
                  width: 100%;
                }

                .star {
                  float: left;
                  width: 50%;
                  position: relative;

                  & > div {
                    & > svg {
                      width: 110px;
                      height: 110px;
                      fill: #fdb92c;
                    }

                    span {
                      position: absolute;
                      top: 38%;
                      -webkit-transform: translateY(-50%);
                      transform: translateY(-50%);
                      left: 42px;
                      font-size: 17px;
                      font-weight: 700;
                      color: #fff;
                    }
                  }

                  p {
                    margin: 0;
                    font-size: 12px;
                  }
                }

                .reting {
                  width: 50%;
                  float: left;

                  .reting_inner {
                    display: flex;
                    align-items: center;

                    & > div {
                      display: inline-block !important;

                      .react-stars {
                        span {
                          margin-right: 5px;
                          color: #fdb92c !important;
                        }

                        margin-right: 8px;
                      }
                    }

                    span {
                      display: inline-block;
                    }
                  }
                }
              }

              .review-form {
                width: 50%;

                @media (max-width: 991px) {
                  width: 100%;
                }

                .rl-user-info {
                  span {
                    font-size: 30px !important;
                    margin-right: 5px;
                  }
                }

                p {
                  margin-bottom: 5px;
                }

                form {
                  width: 100%;
                  max-width: 767px;

                  input {
                    width: 100%;
                    min-height: 35px;
                    font-size: 16px;
                    padding: 8px;

                    &:focus {
                      outline: none;
                      border: 1px solid #cdaf73;
                      box-shadow: none;
                    }
                  }

                  p {
                    font-size: 12px;
                    font-style: italic;
                    margin-top: 5px;
                  }

                  .btn-black {
                    font-size: 16px;
                    font-weight: 500;
                    padding: 8px 20px;
                    border-style: solid;

                    &:hover {
                      background-color: #cdaf73;
                      border-color: #cdaf73;
                      color: #fff;
                    }
                  }
                }
              }
            }

            .rating-list-wrap {
              border-top: 2px dotted #a9a9a9;
              border-bottom: 1px solid #a9a9a9;
              margin-top: 30px;

              .rating-list {
              }

              .top-section {
                display: flex;
                width: 100%;
                justify-content: space-between;
                align-items: center;

                @media (max-width: 575px) {
                  flex-direction: column;
                  align-items: flex-start;
                  padding: 15px 0;
                }

                h5 {
                  font-size: 20px;
                  font-weight: 400;
                  margin-bottom: 5px;
                  margin-top: 5px;
                }

                span {
                  padding-right: 10px;
                }

                select {
                  padding: 5px;
                  font-size: 14px;
                }
              }

              .rating-listing {
                .rating-listing-inner {
                  max-height: 500px;
                  overflow-y: auto;
                  .rating-list-item {
                    display: flex;
                    flex-wrap: wrap;
                    width: 100%;
                    margin-top: 20px;

                    .rl-user-info {
                      @media (min-width: 991px) {
                        width: 30%;
                        padding: 10px;
                      }

                      & > div {
                        .react-stars {
                          span {
                            margin-right: 5px;
                            color: #fdb92c !important;
                          }
                        }
                      }

                      p {
                        color: green;
                      }
                    }

                    .rating-content {
                      @media (min-width: 991px) {
                        width: 70%;
                        padding: 10px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  .loading-wrap {
    display: block;

    .spinner-border {
      position: relative;
      top: 150px;
    }
  }
}

@media (max-width: 767px) {
  .gallery-larger-image {
    display: none !important;
  }

  .pc-main-image-wrap > div > div {
    display: none !important;
  }
}


.variant {
  display: inline-block;
  margin-right: 10px;
  margin-top: 8px;
  margin-bottom: 8px;
  select {
    padding: 3px  6px;
  }
}

