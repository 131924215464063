.order-management {
    .box-size {
        margin-bottom: 15px;
    }
}


.feedback_orders {

   >ul {

        li {
            padding: 10px;
            width: 100%;
            padding: 15px 10px 20px;
            border-bottom: 1px solid hsla(0, 0%, 50.2%, .4117647058823529);
            background-color: #fff;

            ul {
                display: flex;
                justify-content: space-between;
                align-items: center;

                @media (max-width: 575px){
                    flex-direction: column;
                }

                li {
                    padding: 0;
                    border: 0;
                    box-shadow: none;
                    padding: 10px;

                    &.image {
                        max-width: 160px;
                        margin-right: 10px;
                        img{
                            width: 100px;
                            height: 100px;
                            object-fit: contain;
                        }

                    }

                    &.details {
                        @media (max-width: 575px){
                            margin-top: 10px;
                        }
                        a {
                            display: block;
                            font-weight: bold;
                            font-size: 16px;
                        }

                        span {
                            display: block;
                            padding: 15px 0;
                            font-weight: bold;
                            color: #95a5a6;
                        }
                    }
                }
            }
        }
    }

}