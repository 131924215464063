.vd-middle-section {
display: flex;

.left-col {
  width: 280px;
  min-height: 800px;
  height: 100%;
  background-color: #fff;
  opacity: 1;
  transition: all 0.2s linear;
  z-index: 9;
  top: 102px;

  .dashboard-menu-wrap {
    position: fixed;
    top: 102px;
    width: 100%;
  max-width: 280px;

  }

  .mobile-menu-toggle {
    display: none;
    padding: 10px;
    background-color: #fff;
    font-size: 18px;
    cursor: pointer;
    transition: all 0.2s linear;
    left: 280px;
    position: fixed;
    z-index: 10;

    svg {
      font-size: 16px;
      margin-right: 8px;
      transition: all 0.2s linear;
    }

    &:hover {
      color: #cdaf73;

      svg {
        color: #cdaf73;
      }
    }

    @media (max-width: 798px) {
      display: none;
    }

    @media(max-width:460px) {
      padding: 5px;
      font-size: 16px;

      svg {
        font-size: 16px;
      }
    }

    @media(max-width:320px) {

      font-size: 0;
    }

  }


  @media (max-width: 768px) {
    position: fixed;
    left: -280px;
    height: 100%;
    background-color: #fff;
    opacity: 1;
    transition: all 0.2s linear;
    z-index: 20;

    &.dash-menu-open {
      left: 0;
    }
  }

  @media(max-width:460px) {
    top: 76px;
  }
}

.right-col {
  width: calc(100% - 280px);
  background-color: #efefef;
  padding-top: 102px;

  @media (max-width: 768px) {
    width: 100%;
  }

  @media(max-width:460px) {
    padding-top: 76px;
  }

  .right-col-inner.cm-col-inner {
    position: relative;
    padding: 25px;

    @media(max-width:460px) {
      padding: 25px 15px;
    }

    .mobile-menu-toggle {
      display: none;
      position: fixed;
      padding: 10px;
      background-color: #fff;
      font-size: 18px;
      cursor: pointer;
      transition: all 0.2s linear;
      z-index: 10;

      svg {
        font-size: 24px;
        margin-right: 5px;
        transition: all 0.2s linear;
      }

      &:hover {
        color: #cdaf73;

        svg {
          color: #cdaf73;
        }
      }

      @media (max-width: 798px) {
        display: block;
      }

      @media(max-width:460px) {
        padding: 5px;
        font-size: 16px;

        svg {
          font-size: 20px;
        }
      }
    }
    @media(max-width:991px) {
      padding: 25px 15px;
    }
    .select-address-wrap .sd-inner .sd-listing .sd-list-item{
      width:100%;
      @media(min-width:992px){
        width:50%;
        margin-right: 0px;
      }
    }

    .select-address-wrap .sd-inner .asw-button-wrap{
      display: none;
    }
  }
}
}

.vd-main-screen-wrap.dash-menu-open {
.left-col {
  @media (max-width: 768px) {
    left: 0;

    .mobile-menu-toggle {
      display: block;

    }
  }

}

.right-col {
  @media (max-width: 768px) {
    .right-col-inner.cm-col-inner {
      .mobile-menu-toggle {
        display: none;
      }
    }
  }

}

@media (max-width: 768px) {
  &:after {
    content: "";
    background-color: #58585859;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    z-index: 9;
    cursor: pointer;
    display: none;
  }

}
}


///////////////////////////// Vendor Dashboard////////////////////////
.vd-dashboard-wrap {
.title {
  h3 {
    color: #000;
    font-size: 30px;
    text-transform: capitalize;
  }
}



form {
  .input {
    min-height: 35px;
    border-radius: 0;
    border: 1px solid #ced4da;

    &[type="file"] {
      height: 40px;
      max-width: 140px;
      position: relative;
      visibility: hidden;

      &:after {
        content: "Upload File";
        position: absolute;
        color: #cdaf73;
        left: 0;
        top: 50%;
        left: 50%;
        text-align: center;
        width: 100%;
        transform: translate(-50%, -50%);
        z-index: 9;
        visibility: visible;
        border: 1px solid #999;
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
      }
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px #f3d28f4d;
    }
  }

  select {
    word-wrap: normal;
    color: #3a3a3a;
    border: 1px solid #ced4da;
    font-size: 18px;
    min-height: 35px;
    display: block;
    width: 100%;
    padding: 10px 15px;

    &:focus {
      outline: none;
      box-shadow: 0 0 0 3px #f3d28f4d;
    }
  }

  button {
    margin-top: 50px;
    border: none;
    border-radius: 0;
  }
  .requered{
    color: red;
  }
  .helptext {
    font-size: 10px;
    font-style: italic;
    color: #9e9e9e;
}
}
.cm-autocomplete {
  z-index: 10;
  position: relative;

  >div {
      display: block;
      width: 100%;
      z-index: 9;
  }
  input {
      min-height: 35px;
      border-radius: 0;
      color: #3a3a3a;
      padding: 10px 15px;
      border: 1px solid #ced4da;
      width: 100%;
      &:focus{
          outline: none;
          box-shadow: 0 0 0 3px #f3d28f4d;
      }
  }
}
}