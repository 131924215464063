.ftd-fw-form {
  padding: 25px 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 7px 29px 0 #64646f33;

  label {
    // display: block;
  }

  .input {
    width: 100%;
    min-height: 35px;
    border-radius: 0;
    border: 1px solid #ced4da;

    &[type="file"] {
      position: absolute;
      opacity: 0;
      min-height: auto;
      padding: 0;
      height: 100%;
      width: 100%;
      top: 30px;
      left: 0;
      cursor: pointer;

      &.active:hover {
        &:after {
          border: 1px solid #ced4da;
          content: "Change Image";
        }
      }
    }
  }

  .cm-file-upload {
    .previewImage {
      img {
        max-width: 200px;
      }
    }

    .delete-img {
      position: absolute;
      left: 18px;
      top: 25px;
      font-size: 35px;
      color: #ff0000d9;
      cursor: pointer;
      opacity: 0.6;
      transition: all 0.025s linear;

      &:hover {
        opacity: 1;
      }
    }

  }

  span.req {
    color: red;
    margin-left: 2px;
  }

  .btn-brown {
    margin-top: 50px;
    border: none;
    border-radius: 0;
  }

  .ftd-auto-select {
    &>div {
      display: block !important;
    }

    input {
      word-wrap: normal;
      color: #3a3a3a;
      border: 1px solid #ced4da;
      font-size: 18px;
      min-height: 35px;
      display: block;
      width: 100%;
      padding: 10px 15px;

      &:focus {
        outline: none;
        box-shadow: 0 0 0 3px #f3d28f4d;
      }
    }
  }

  .ftd-selected-countries-wrap {
    margin-top: 70px;

    h4 {
      text-align: center;
      font-size: 18px;
    }
  }

  .ftd-selected-wrap {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    .ftd_selected_country {
      margin-right: 20px;
      margin-bottom: 20px;
      color: #fff;
      border-radius: 20px;
      letter-spacing: 1.5px;
      padding: 10px 15px;
      background-color: #d7c298;

      svg {
        font-size: 20px;
        margin-left: 8px;
        font-size: 20px;
        margin-left: 8px;
        color: #ff0000c4;
        cursor: pointer;
      }
    }
  }
}


//////////////////////// Store //////////////////////

.store {
  h4 {
    font-size: 20px;
    border-bottom: 2px solid;
    display: inline-block;
    margin-bottom: 10px;
  }

  ul {
    columns: 2;

    @media (max-width: 991px){
          columns: 1;
    }

    li {
      font-size: 16px;
      margin-bottom: 10px;
      color: #95a5a6;

      strong {
        display: block;
      }
    }
  }
}

.mb-4.visible-upload .input[type="file"] {
  position: relative;
  opacity: 1;
  min-height: auto;
  height: auto;
  width: 100%;
  top: 0;
  left: 0;
  cursor: pointer;
}