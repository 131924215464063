@import "../../sass/abstracts/Variable";




.how_it_works {
    .container {
        display: block;
        .how_it_works_inner {
            display: flex;
            width: 100%;
            padding: 10px 30px;

            @media (max-width: 991px) {
                flex-direction: column;
                padding: 0 15px;
            }

            .title {
                width: 40%;
                padding-left: 25px;
                padding-bottom: 100px;

                @media (max-width: 991px) {
                    width: 100%;
                    padding: 10px 0;
                    text-align: center;
                    order: 2;
                }

                h3 {
                    font-size: 25px;
                    line-height: 1.1;
                    // color: $blue-color;
                    padding: 5px 0;

                    @media (max-width: 991px) {
                        padding: 10px 0;
                        font-size: 20px;
                    }
                }

                ul {
                    margin-bottom: 30px;

                    li {
                        font-size: 16px;
                        vertical-align: middle;
                        display: block;

                        @media (max-width: 991px) {
                            font-size: 15px;
                        }

                        .icon {
                            font-size: 18px;
                            padding-right: 9px;
                        }
                    }
                }
            }

            .number {
                width: 20%;
                margin: 0 25px;
                position: relative;

                @media (max-width: 991px) {
                    display: none;
                }

                &::before {
                    content: "";
                    position: absolute;
                    border: 1px dotted #063553;
                    height: 100%;
                    left: 50%;
                    top: 0;
                    transform: translatex(-50%);
                    z-index: -1;
                }

                .number_inner {
                    font-size: 47px;
                    width: 102px;
                    height: 102px;
                    margin: 0 auto;
                    border: 3px dotted #063553;
                    vertical-align: middle;
                    border-radius: 50%;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    z-index: 1;
                    background-color: #fff;
                }
            }

            .image {
                width: 40%;
                text-align: center;

                @media (max-width: 991px) {
                    width: 100%;
                }
            }

            &:nth-child(even) {
                .image {
                    order: 1;
                }

                .number {
                    order: 2;
                }

                .title {
                    order: 3;
                }
            }

            &:last-child {
                .number {
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}

.become_partner {
    .container {
        display: block;
    }

    .page_title {
        text-align: center;
        // padding-bottom: 35px;

        h3 {
            font-size: 40px;
            font-weight: bold;
            text-transform: capitalize;
            padding: 5px 0;
            color: #063553;

            &:after {
                display: none;
            }
        }
    }

    .kyc {
        h4 {
            font-size: 26px;
            text-align: center;
            line-height: 1.2;
            font-weight: 400;
            color: #0a0a0a;

            @media (max-width: 991px) {
                font-size: 20px;
            }
        }

        .card_wrap {
            display: flex;
            justify-content: space-around;
            padding: 60px 0;
            padding-bottom: 0;

            @media (max-width: 991px) {
                padding: 20px 0;
                flex-wrap: wrap;
            }

            .kyc_card {
                width: 100%;
                max-width: 350px;
                text-align: center;
                padding: 25px 15px;
                box-shadow: #0000000d 0px 6px 24px 0px,
                    #00000014 0px 0px 0px 1px;
                border-radius: 5px;

                @media (max-width: 991px) {
                    margin-bottom: 20px;
                }

                .card_body {
                    h4 {
                        font-size: 25px;
                        padding-top: 28px;
                        padding-bottom: 9px;

                        @media (max-width: 991px) {
                            padding-top: 15px;
                        }
                    }
                }
            }
        }
    }

    .how_it_works {
        padding-top: 60px;

        .container {
            display: block;


        }
    }

    .news_letter {
        .container {
            display: flex;

            @media (max-width: 991px) {
                display: block;
            }
        }
    }
    .form-wrap {
        max-width: 767px;
        margin: 0 auto;
        .input {
            border: 1px solid grey;
            transition: all 0.15s linear;
            margin-bottom: 0;
            min-height: 44px;
            display: block;
            width: 100%;
            padding: 5px 10px;
            font-size: 16px;
            line-height: 1.2;
            border-radius: 0;
            &:focus {
                outline: none;
                border: 1px solid #cdaf73;
                box-shadow: none;
            }
        }
    }
    button {
        margin-top: 20px;
        font-size: 20px;
        font-weight: 500;
        border-radius: 0;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    
   
}

.become_partner .h-banner-inner{
    background-color: #063553;
}