@import "../../sass/abstracts/_Variable.scss";

.signup-m-wrap {
  padding-top: 70px;
  padding-bottom: 70px;
  .top-text{
      h1{
          line-height: 1.2;
          margin-bottom: 0;
      }
  }
  .img-wrap {
    height: 100%;
    img{
        height: 100%;
    }
    @media (max-width: 767px) {
      display: none;
    }
  }
 .slider{
  @media (max-width: 767px) {
    display:none;
  }
 }

  .signup-form-wrap {
    height: 100%;
    padding: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    a {
      color: #cdaf73;
    }
    p {
      font-size: 18px;
      font-weight: 500;

      a {
        color: #cdaf73;
      }
    }

    .signup-form {
        
      margin-top: 50px;
      .select-country-input {
        label {
          display: block;
          width: 100%;
        }
        .input {
          display: inline-block;
          width: calc(100% - 125px);
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          border-left: 0;
        }
        .dropdown.btn-group {
          width: 100%;
          vertical-align: top;
          .dropdown-menu{
            min-width: 125px;
            max-height: 260px!important;
            overflow-x: hidden;
            overflow-y: scroll!important;
          }
          .dropdown-item {
            color: #000;
            padding: 10px;
            &.active {
              background-color: #cdaf73;
            }
          }
          img {
            max-width: 35px;
            width: 100%;
            margin-left: 2px;
            margin-right: 2px;
          }
          .input.dropdown-toggle {
            background-color: transparent;
            color: #000;
            padding: 10px;
            display: inline-block;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
            border-left: 1px;
            border-color: #000;
            border-style: solid;
            text-align: left;

            &:focus,
            &:hover {
              outline: none;
              border: 1px solid #cdaf73;
              box-shadow: 0 0 0 3px #f3d28f4d;
            }
            &:after{
              float: right;
              top: 8px;
              position: relative;
            }

            img {
              max-width: 35px;
              width: 100%;
              margin-left: 2px;
              margin-right: 2px;
            }
          }
        }
      }

      label {
        font-size: 16px;
        font-weight: 500;

        &.forget-password.form-label {
          float: right;

          a {
            font-size: 14px;
            color: #cdaf73;
          }
        }
      }

      .btn-brown {
        width: 100%;
        margin-top: 30px;
        margin-bottom: 30px;
      }

      .form-check {
        display: inline-block;
        input[type="checkbox"] {
          width: 20px;
          font-size: 40px;
          height: 24px;
          margin-top: -3px;
        }

        label {
          margin-left: 10px;
          margin-bottom: 0;
        }
      }
      
    }
    .select-user-wrap{
        .form-check{
            display: inline-block;
            margin-right:15px;
            input[type="radio"] {
              width: 15px;
              font-size: 40px;
              height: 24px;
              margin-top: 0px;
            }
        }
      label{
          font-size: 18px;
          margin-left:0;
      }
  }
  }
}
