.first-time-detail-form-wrap {
  padding: 20px;

  .ftd-inner {
    .ftd-alert {
      padding: 15px 15px;
      background-color: #fdcb6e9e;
      color: #ff0000c2;

      p {
        margin: 0;
      }

      h6 {
        color: #ff0000c2;
        margin-bottom: 5px;

        span {
          margin-right: 8px;
          font-size: 22px;

          svg {
            color: #ee5a24;
          }
        }
      }
    }

    .ftd-form-wrap {
      margin-top: 50px;

      .ftd-f-steps-wrap {
        text-align: center;

        .steps-inner {
          position: relative;

          .currentSteps {
            display: inline-block;
            width: 50px;
            background-color: #cdaf73b3;
            height: 50px;
            border-radius: 50px;
            color: #fff;
            position: relative;

            &:after {
              content: "";
              width: 2px;
              border-radius: 40px;
              background-color: #d7c298;
              height: 43px;
              position: absolute;
              transform: rotate(24deg);
              right: -2px;
              top: 20px;
            }

            span {
              font-size: 20px;
              font-weight: 700;
              top: 12px;
              position: relative;
            }
          }

          .totalSteps {
            display: inline-block;
            width: 50px;
            height: 50px;
            border-radius: 50px;
            top: 30px;
            position: relative;

            span {
              font-size: 13px;
              font-weight: 500;
            }
          }
        }
      }

      .ftd-fw-main-section {
        .ftd-fw-heading {
          margin-top: 60px;

          h1 {
            font-size: 25px;
          }
        }

        .ftd-fw-form {
          margin-top: 20px;

          .input {
            min-height: 35px;
            border-radius: 0;
            border: 1px solid #ced4da;

            &[type="file"] {
              position: absolute;
              opacity: 0;
              min-height: auto;
              padding: 0;
              height: 100%;
              width: 100%;
              top: 30px;
              left: 0;
              cursor: pointer;

              &.active:hover {
                &:after {
                  border: 1px solid #ced4da;
                  content: "Change Image";
                }
              }
            }

            &:focus {
              outline: none;
              box-shadow: 0 0 0 3px #f3d28f4d;
            }
          }

          select {
            word-wrap: normal;
            color: #3a3a3a;
            border: 1px solid #ced4da;
            font-size: 18px;
            min-height: 35px;
            display: block;
            width: 100%;
            padding: 10px 15px;

            &:focus {
              outline: none;
              box-shadow: 0 0 0 3px #f3d28f4d;
            }
          }

          button {
            margin-top: 50px;
            border: none;
            border-radius: 0;
          }

          img {
            max-width: 35px;
            width: 100%;
            margin-left: 2px;
            margin-right: 2px;
          }

          .previewImage {
            &.active:hover {
              &:after {
                content: "Change Image";
              }
            }

            img {
              width: auto;
              max-width: 300%;
              object-fit: cover;
              margin: 0 auto;
              max-width: 100%;
              min-height: 100px;
              max-height: 200px;
            }
          }

          .ftd-auto-select {
            &>div {
              display: block !important;
            }

            input {
              word-wrap: normal;
              color: #3a3a3a;
              border: 1px solid #ced4da;
              font-size: 18px;
              min-height: 35px;
              display: block;
              width: 100%;
              padding: 10px 15px;

              &:focus {
                outline: none;
                box-shadow: 0 0 0 3px #f3d28f4d;
              }
            }
          }

          .ftd-selected-countries-wrap {
            margin-top: 70px;

            h4 {
              text-align: center;
              font-size: 18px;
            }
          }

          .ftd-selected-wrap {
            margin-top: 30px;
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .ftd_selected_country {
              margin-right: 20px;
              margin-bottom: 20px;
              color: #fff;
              border-radius: 20px;
              letter-spacing: 1.5px;
              padding: 10px 15px;
              background-color: #d7c298;

              svg {
                font-size: 20px;
                margin-left: 8px;
                font-size: 20px;
                margin-left: 8px;
                color: #ff0000c4;
                cursor: pointer;
              }
            }
          }
        }
      }
    }

    .ftd-pending-approval {
      margin-top: 50px;

      .ftd-pending-approval-inner {
        padding: 20px;
        text-align: center;

        .ftd-pa-ing {
          img {
            max-width: 300px;
          }
        }

        .ftd-pa-content {
          margin-top: 20px;

          p {
            margin: 0;
            font-size: 22px;
          }
        }
      }
    }
  }
}