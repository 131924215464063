.dashboard-footer {
    text-align: center;
    background-color: #fff;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #9e9e9e;
    font-size: 18px;
    p {
        margin-bottom: 0;
    }
}

