@import "../../sass/abstracts/_Variable.scss";

.login-m-wrap {
  padding-top: 70px;
  padding-bottom: 70px;
  .img-wrap {
    @media(max-width:767px) {
    display: none;
    }
    
}
.slider{
  overflow: hidden;
  @media(max-width:767px) {
  display:none;
  }
}

  .login-form-wrap {
    position: relative;
    height: 100%;
    padding: 20px;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;

    p {
      font-size: 18px;
      font-weight: 500;

      a {
        color: #cdaf73;
      }
    }

    .login-form {
      margin-top: 50px;

      label {
        font-size: 16px;
        font-weight: 500;

        &.forget-password.form-label {
          float: right;

          a {
            font-size: 14px;
            color: #cdaf73;
          }
        }
      }

      .btn-brown {
        width: 100%;
        margin-top: 30px;
        margin-bottom: 30px;
      }

      .form-check {
        input[type="checkbox"] {
          width: 20px;
          font-size: 40px;
          height: 24px;
          margin-top: -1px;
        }

        label {
          margin-left: 10px;
        }
      }
    }
  }
}

.item.img-wrap {
  text-align: center;
}