/** @format */

@import "../../sass/abstracts/Variable";

.faqs-wrap {
  margin-top: 70px;
  margin-bottom: 35px;
  min-height: 400px;
  .tab-content {
    margin-top: 35px;
  }
  .accordion-item {
    border-bottom: 1px solid #80808047;
  }
  .accordion-header {
    margin: 0;
    line-height: 1;
    margin-bottom: 10px;
    .accordion-button {
      width: 100%;
      text-align: left;
      border: navajowhite;
      padding: 15px 10px;
      line-height: 1;
      font-size: 20px;
      position: relative;
      background-color: transparent;

      &[aria-expanded="true"]:after {
        content: "-";
        font-size: 38px;
        top: 4px;
      }
      &[aria-expanded="true"] {
        background-color: #e4c1732e;
      }
      &::after {
        flex-shrink: 0;
        width: 1.25rem;
        height: 1.25rem;
        margin-left: auto;
        position: absolute;
        content: "+";
        right: 10px;
        background-repeat: no-repeat;
        background-size: 1.25rem;
        transition: transform 0.2s ease-in-out;
      }
      @media (max-width: 991px) {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  @media (max-width: 768px) {
    .accordion-body p {
      font-size: 12px;
    }
  }
  .accordion-collapse {
      .accordion-body{
        padding: 15px 10px;
      }
   
  }
  .nav-tabs {
    border: none;
    @media (max-width: 767px) {
      justify-content: center;
    }
    .nav-item{
        @media (max-width: 767px) {
            width: 50%;
          }
    }
    .nav-link {
      border: none;
      font-weight: 500;
      font-size: 18px;
      text-align: center;
      padding-left: 30px;
      padding-right: 30px;
      @media (max-width: 991px) {
        margin-bottom: 10px;
        width: 100%;
      }
      @media (max-width: 991px) {
        font-size: 16px;
        padding-left: 10px;
        padding-right: 10px;
        padding-left: 20px;
        padding-right: 20px;
      }
      &.active {
        color: #fff;
        background-color: #063553;
      }
    }
  }
}
