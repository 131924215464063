.static-pages {
  .container {
    .container-inner {
        // box-shadow: rgba(0, 0, 0, 0.1) 0px 20px 25px -5px, rgba(0, 0, 0, 0.04) 0px 10px 10px -5px;
      padding: 0 15px 30px 15px;
      margin-top: 50px;
      margin-bottom: 50px;
      min-height: 450px;
      .heading-section {
        h1 {
          margin-bottom: 30px;
          font-size: 35px;
          border-bottom: 1px solid grey;
          padding-bottom: 5px;
          border-bottom: 1px solid #808080c9;
        }
      }
    }
  }
}
