.dashboard-menu-wrap {
  .dh-menu-inner {
    ul.level-1 {
      margin: 0;
      padding: 0;
      overflow-y: auto;
      height: calc(100vh - 90px);
      li {
        padding: 10px 20px;
        position: relative;
        a {
          font-size: 16px;
          font-weight: 400;
          transition-duration: 0.15s;
          width: 100%;
          span {
            margin-right: 10px;
            font-size: 18px;
          }
        }
        &.active{
          >a{
            color:#cdaf73
          }
        }

        ul.level-2 {
          position: absolute;
          visibility: hidden;
          opacity: 0;
          li {
            a {
            }
          }
        }
        &.child-menu-open {
          ul.level-2 {
            position: relative;
            visibility: visible;
            opacity: 1;
            
          }
          .childTrigger {
            svg {
              transform: rotate(180deg);
            }
          }
        }

        .childTrigger {
          position: absolute;
          right: 12px;
          top: 10px;
          width: 20px;
          height: 20px;
          cursor: pointer;
          transition: all 0.15s linear;
          svg {
            font-size: 18px;
            transition: all 0.25s linear;
          }
          &:hover svg {
            color: #cdaf73;
          }
        }
      }
    }
  }
}
