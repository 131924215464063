.shopping_cart {
  h3 {
    font-size: 28px;
    text-transform: capitalize;
  }

  .container {
    display: flex;
    justify-content: space-between;

    @media (max-width: 767px) {
      flex-wrap: wrap;
    }
  }

  .content_left {
    width: 100%;
    // padding: 20px;
    max-width: calc(100% - 320px);

    @media (max-width: 767px) {
      max-width: 100%;
    }

    .sub_title {
      box-shadow: 0px 3px 6px #00000029;
      padding: 10px 20px;
      margin-bottom: 20px;
    }

    .ship_product {
      .item {
        padding: 20px;
        box-shadow: 0px 3px 6px #00000029;

        @media (max-width: 767px) {
          padding: 20px 10px;
          overflow: auto;
        }

        table {
          width: 100%;

          @media (max-width: 767px) {
            width: 700px;
            overflow-x: visible;
          }

          tr {
            th {
              text-transform: uppercase;
              text-align: center;
            }

            td {
              text-align: center;
              color: #063553;
              font-weight: 500;

              img {
                width: 100%;
                max-width: 90px;
              }

              h6 {
                margin-bottom: 5px;
                color: #063553;
              }

              del {
                display: block;
                color: #5a5a5a;
              }

              span {
                svg {
                  fill: red;
                  cursor: pointer;
                }
              }

              &.button {
                .qty {
                  span:not(:last-child) {
                    border-right: 0;
                  }

                  svg {
                    fill: #cdaf73;
                  }
                }
                span {
                  padding: 3px 6px;
                  border: 1px solid #707070;
                  font-size: 14px;
                  display: inline-block;
                  margin: 0.1px;
                  cursor: pointer;

                  // @media (max-width: 530px){
                  //     display: block;
                  // }
                }
              }
            }
          }
        }
      }
    }
  }

  .content_right {
    width: 100%;
    max-width: 300px;
    padding: 20px;
    box-shadow: 0px 3px 6px #00000029;

    @media (max-width: 767px) {
      max-width: 100%;
    }

    h5 {
      @media (max-width: 767px) {
        font-size: 20px;
        margin-top: 20px;
      }
    }

    ul {
      width: 100%;
      border-bottom: 1px solid #aaa;
      padding: 15px 0 25px;

      li {
        width: 100%;
        display: flex;
        justify-content: space-between;
        padding: 5px 0;
        margin-bottom: 10px;

        b {
          font-size: 20px;
          margin-top: 20px;
        }
      }
    }

    .button_wrap {
      width: 100%;
      padding-top: 15px;
      text-align: center;

      a {
        display: block;
        text-transform: uppercase;
        color: #fff;
        background: #063553;
        border-radius: 25px;
        padding: 10px 20px 8px;
        border: 2px solid #063553;

        &:hover {
          color: #063553;
          background-color: transparent;
          font-weight: bold;
        }
      }
    }
  }
}

.container.no-product {
  flex-wrap: wrap;
justify-content: center;
  .empty-product-img {
    width: 100%;
    box-shadow: #64646f33 0px 7px 29px 0px;
  text-align    : center;
    img {
      max-width: 450px;
      width: 100%;
      margin: 0 auto;
      text-align: center;
    }
  }
  h1 {
    text-align: center;
    font-size: 25px;
    margin-top: 20px;
    color: #fc0303d1;

  }
}
