.all-main-product-wrap {
  .amp-content {
    .searchbar {
      text-align: right;
      input {
        border: 1px solid #8f8e8ef2;
        padding: 5px 8px;
        border-radius: 5px;
        margin: 0 10px 5px 0;
      }
    }
    a.editButton {
      cursor: pointer;
      transition: all 0.15s linear;
      svg {
        transition: all 0.15s linear;
        font-size: 18px;
      }
      &:hover {
        svg {
          font-size: 20px;
          text-shadow: 2px 2px 2px grey;
        }
      }
    }
  }
}

img.p-image {
  object-fit: contain;
}
