.wallet {
    .wallet_inner {
        // max-width: 767px;
        margin: 0 auto;
        padding: 20px 0;
        display: flex;
        justify-content: space-around;

        @media (max-width: 767px) {
            flex-direction: column;
        }

        ._card {
            width: 100%;
            text-align: center;
            padding: 15px 20px;
            border-radius: 5px;
            margin: 5px 10px;
            box-shadow: #00000033 0px 1px 3px 0px,
                #1b1f2326 0px 0px 0px 1px;
            transition: all 0.2s linear;
            background-color: #217ff333;
            position: relative;

            &:hover {
                transform: scale(1.02);
                // box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px,
                // rgba(0, 0, 0, 0.12) 0px -12px 30px,
                // rgba(0, 0, 0, 0.12) 0px 4px 6px,
                // rgba(0, 0, 0, 0.17) 0px 12px 13px,
                // rgba(0, 0, 0, 0.09) 0px -3px 5px;
            }

            .card_img {
                font-size: 50px;
                color: #063553;
            }

            .card_content {
                p {
                    margin-bottom: 0;
                }

                h5 {
                    font-size: 16px;

                    span {
                        color: #65ba69;
                        font-size: 35px;
                        display: block;
                        font-weight: bold;
                    }
                }

            }

            a {
                position: absolute;
                top: 0;
                width: 100%;
                height: 100%;
                left: 0;
            }
        }
    }
}





////////////////////////////////////////////

.transation {
    .tab-content {

        .inner_content {
            ul {
                li {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    padding: 10px 15px;
                    align-items: center;
                    box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px,
                        rgb(209, 213, 219) 0px 0px 0px 1px inset;
                    border-radius: 5px;

                    @media (max-width: 575px){
                        flex-wrap: wrap;
                        flex-direction: column;
                            margin-bottom: 5px;


                    }
                    &.status-1 {
                        background-color: #fff;
                    }


                    .image {
                        padding: 15px;
                        width: 100%;
                        max-width: 100px;
                    }

                    .details {
                        text-align: center;

                        h5 {
                            @media (max-width: 767px){
                                font-size: 18px;
                            }
                        }

                        span {
                            display: block;

                        }
                    }

                    .amount {
                        padding: 5px;

                        h5 {
                            font-size: 25px;

                            @media (max-width: 767px) {
                                font-size: 16px;
                            }
                        }
                    }

                    p.reason {
                        color: red;
                        font-weight: 500;
                        font-size: 12px;
                        margin-top: 5px;
                        margin-bottom: 0;
                        font-style: italic;
                    }

                    span.status-1 {
                        background-color: #ffa5005c;
                        border-radius: 45px;
                        font-weight: 500;
                        padding: 2px 15px;
                        font-size: 15px;
                        min-width: 125px;
                        font-style: italic;
                        display: inline-block;

                        @media (max-width: 767px){
                            font-size: 14px;
                            margin: 5px 0;
                        }
                    }

                    span.status-1.Approved {
                        background-color: #2ed95794;
                    }

                    span.status-1.Rejected {
                        background-color: #ff0000a6;
                    }
                }
            }
        }
    }
}

//  withdraw money request
.cancel-order-inner.request-withdraw .modal-header {
    background-color: #64b558;
}

.cancel-order-inner.request-withdraw .modal-footer button.btn-danger {
    color: #64b558;
}

.cancel-order-inner.request-withdraw input.input {
    width: 100%;
    display: block;
    min-height: 35px;
    padding: 5px 8px;
}


// modal payment withdraw
.payment-mode{
    input[type=radio]{
        display: none;
    }
    .btn{
        margin-right: 5px;
    }
}

