/** @format */

.slider3-wrap {
  .slider-heading-wrap {
    h2 {
      font-size: 30px;
      line-height: 1.57;
      margin-bottom: 20px;
      text-transform: uppercase;
      @media (max-width: 991px) {
        font-size: 25px;
      }
      @media (max-width: 767px) {
        font-size: 20px;
      }
    }
  }

  .slider-wrap-inner-3 {
    svg,
    .slick-prev,
    .slick-next {
      font-size: 30px;
      height: 30px;
      width: 30px;
      @media (max-width: 767px) {
        height: 20px;
        width: 20px;
      }
    }
    .slick-prev {
      left: -35px;
      @media (max-width: 767px) {
        left: -25px;
      }
      @media (max-width: 640px) {
        display: none !important;
      }
    }
    .slick-next {
      right: -35px;
      @media (max-width: 767px) {
        right: -25px;
      }
      @media (max-width: 640px) {
        display: none !important;
      }
    }
  }
}

.slider3-img -wrap a {
  // border: 1px solid #d1d1d1;
  height: auto;
  // max-width: 210px;
  height: 280px;
  width: 100%;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px;
}

.slider3-img -wrap {
  margin-right: 10px;
  text-align: left;
}

.slider3-img -wrap:first-child {
  // margin-left: 0px;
}

.slider-item3 {
  // height: 100%;
  // width: 100%;
  // flex-wrap: wrap;
  // display: flex;
  // flex-direction: column;
  // align-items: stretch;
  // justify-content: space-between;
}

.slider3-img     img {
  max-height: 200px;
  object-fit: contain;
  width: 100%;
  width: 200px;
  padding:15px 10px;
  margin: 0 auto;
  height: 200px;
}

.content-wrap h4 {
  font-size: 20px;
  font-weight: 400;
}

.content-wrap {
  padding: 20px;
  p {
    color: #000;
    margin-top: 5px;
    font-weight: 500;
    strike {
      color: grey;
    }
  }
  // text-align: center;
}

.tag-wrap span {
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  color: #cdaf73;
  background-color: #000;
  padding: 5px 15px;
  border-radius: 5px;
  line-height: 1.2;
}

.slider3-img -wrap.item-big .content-wrap h4 {
  font-size: 30px;
  font-weight: 400;
}

@media (max-width: 1240px) {
  .slider3-img  img {
    max-height: 200px;
  }
  .slider3-img -wrap a {
    // height: 325px;
  }
}

@media (max-width: 767px) {
  .slider3-img  img {
    max-height: 165px;
  }
  .slider3-img -wrap a {
    // height: 325px;
  }
}

@media (max-width: 767px) {
  .slider3-img  img {
    max-height: 165px;
  }
  .slider3-img -wrap a {
    height: 300px;
  }
  .slider3-img -wrap {
    text-align: center;
    padding-right: 10px;
    margin: 0;
  }
  .content-wrap {
    text-align: left;
  }
}

@media (max-width: 640px) {
  .slider3-img  img {
    max-width: 320px;
  }
}

.slider3-img  {
  width: 100%;
  text-align: center;
}

.slider3-img -wrap {
  display: inline-block;
  margin: 10px;
  padding: 0 5px;
  transition: all 0.25s linear;
  border: transparent solid 1px;
  &:hover {
    transform: translateY(-5px);
  }
}

.content-wrap h4 {
  white-space: normal;
  font-size: 14px;
  /* padding: 15px 0; */
  font-weight: 500;
  margin: 0;
}
.content-wrap p{

margin: 0;
}
