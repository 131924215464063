.profile {
    .vd-dashboard-body {

        @media (max-width: 991px) {
            display: flex;
            flex-wrap: wrap;
        }
    }

    .content-left {
        width: calc(100% - 270px);
        display: inline-block;
        margin-right: 20px;

        @media (max-width: 991px) {
            width: 100%;
            margin-bottom: 20px;
            margin-right: 0;
            order: 2;
        }

        form {

            .row {
                @media (max-width: 1100px) {
                    margin-bottom: 0 !important;
                }

                .col {
                    @media (max-width: 1100px) {
                        flex-basis: unset;
                        margin-bottom: 15px;
                    }
                }
            }
        }
    }

    .content-right {
        width: 250px;
        display: inline-block;
        float: right;

        @media (max-width: 991px) {
            width: 100%;
            float: none;
            order: 1;
            margin-bottom: 20px;
        }

        .profile_card {
            text-align: center;

            .card_head {
                .img {
                    width: 110px;
                    height: 110px;
                    margin: 0 auto;
                    border-radius: 50%;
                    overflow: hidden;

                    label {
                        height: 100%;
                        cursor: pointer;
                        position: relative;
                        width: 100%;
                        border-radius: 50%;


                        img {
                            width: 100%;
                            object-fit: contain;
                            height: 100%;
                        }


                        &:after {
                            content: "Edit";
                            position: absolute;
                            top: 100%;
                            left: 0;
                            width: 100%;
                            height: 100%;
                            background: #cdaf738c;
                            color: #fff;
                            text-align: center;
                            padding-top: 45%;
                            transition: all 0.3s linear;
                        }

                        &:hover {
                            &:after {
                                top: 0;
                            }
                        }
                    }

                    #updateImage {
                        opacity: 0;
                        visibility: hidden;
                    }
                }

                h3 {
                    font-size: 18px;
                    margin: 0;
                    margin-top: 15px;
                    font-weight: bold;
                    line-height: 1.3;
                }

                h4 {
                    font-size: 15px;
                    line-height: 1.3;
                    font-weight: normal;
                    margin: 0;
                    padding: 5px 0;
                }

                .location {
                    padding: 5px 0;
                }

                .seller {
                    padding: 9px 25px 7px;
                    display: inline-block;
                    background: #cdaf73;
                    line-height: 1;
                    border-radius: 15px;
                    margin: 15px 0;
                }
            }

            .card_body {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 15px 10px;

                .count {
                    h4 {
                        font-size: 25px;
                    }

                    span {
                        font-size: 16px;
                        font-weight: bold;
                    }
                }
            }
        }
    }
}