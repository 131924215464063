.our_brands {
    h3 {
        color: #3A3A3A;
        font-size: 24px;
        font-weight: bold;
        width: 100%;
        max-width: 100%;
        margin-bottom: 15px;
        text-align: left;
    }

    .brand_section {
        width: 100%;
        padding-bottom: 30px;
        text-align: center;

        .item {
            max-width: 185px;
            width: 100%;
            height: 138px;
            margin: 5px;

            @media (max-width: 575px) {
                height: auto;
                max-width: 250px;
                margin: 7px;
            }

            a {
                display: block;
                transition: all 0.3s linear;

                &:hover {
                    transform: scale(1.02);
                }
            }
        }
    }

    .valuabale {
        width: 100%;
        text-align: center;

        .item {
            width: 100%;
            max-width: 380px;
            // height: 340px;
            margin: 5px;
        }
    }

    .item {
        display: inline-block;

        a {
            display: block;
            transition: all 0.3s linear;

            img {
                width: 100%;
            }

            &:hover {
                transform: scale(1.02);
            }
        }
    }
}