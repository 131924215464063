.shipping-info {
  .wallet {
    .wallet_inner {
      width: 100%;
      max-width: unset;
      flex-wrap: wrap;
      justify-content: flex-start;
      ._card {
        text-align: left;
        margin: 10px;
        flex: 0 0 calc(50% - 20px);
        background-color:transparent;   
        cursor: pointer;
        min-height: 150px;
        &.active{
            background-color: #65ba6940;
        }
        p {
          text-align: center;
        }
        .active-badge {
            position: absolute;
            top: 8px;
            right: 10px;
            span {
                padding: 2px 10px;
                background-color: #2a23d375;
                border-radius: 5px;
                color: #fff;
                font-weight: 500;
                letter-spacing: 0.5px;
            }
        }
        
      
      }
    }
  }
}
