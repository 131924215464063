/** @format */

.addProduct-variant-wrap {
  .apv-inner {
  }
}

.apv-modal {
  .h4 {
    font-size: 18px;
  }
  label {
    font-size: 14px;
    margin-bottom: 5px;
  }

  .input {
    display: block;
    min-height: 35px;
    border-radius: 0;
    border: 0;
    border-bottom: 1px solid #ced4da;
    width: 100%;
    font-size: 14px;
    padding: 5px 8px;
    &:focus {
      box-shadow: 0 0 0 3px #f3d28f4d;
      border: 0;
      border-bottom: 1px solid #cdaf73;
      box-shadow: none;
    }
    &[type="file"] {
      height: 40px;
      max-width: 140px;
      position: relative;
      visibility: hidden;

      &:after {
        content: "Upload File";
        position: absolute;
        color: #cdaf73;
        left: 0;
        top: 50%;
        left: 50%;
        text-align: center;
        width: 100%;
        transform: translate(-50%, -50%);
        z-index: 9;
        visibility: visible;
        border: 1px solid #999;
        padding: 5px;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
  select {
    option {
      font-size: 16px;
    }
  }
  .btn-brown {
    border-radius: 0;
    padding: 5px 20px;
  }
  .upload-input-wrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .gallery-img-preview {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      margin-bottom: 35px;
      .gip-img {
        max-height: 100px;
        max-width: 100px;
        margin-right: 20px;
        margin-bottom: 20px;
        border: 1px solid #6a6a6a8f;
        padding: 3px;
        position: relative;
        img {
          object-fit: cover;
          max-width: 100%;
          width: 100%;
          height: 100%;
        }
        .delete-img {
          position: absolute;
          left: -15px;
          top: -15px;
          svg {
            font-size: 28px;
          }
        }
      }
    }
  }

  .upload-input-inner {
    max-width: 100px;
    width: 100%;
    margin-left: 10px;
    margin-bottom: 14px;
  }
  .mb-3.cm-file-upload img {
    width: 160px;
  }
  
}

.add-v-attri {
  color: #00000061;
  font-weight: 500;
  margin-top: 5px;
   span {
    color: #cdaf73;
    margin-left: 5px;
    font-size: 19px;
    cursor: pointer;
  }
}

.apv-modal select{
  display: block;
  min-height: 35px;
  border-radius: 0;
  border: 0;
  border-bottom: 1px solid #ced4da;
  width: 100%;
  font-size: 14px;
  padding: 5px 8px;
}
.add-v-attri.delete-icon {
  font-size: 12px;
  color: #d72e2e85;
  margin: 0;
}

.add-v-attri.delete-icon p {
  margin: 0;
  margin: 0;
}

.add-v-attri.delete-icon svg {
  color: #ff00008f;
  margin-left: 6px;
  font-size: 17px;
}