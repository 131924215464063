.slider4-wrap {
  h5 {
    font-size: 30px;
    line-height: 1.57;
    margin-bottom: 20px;
    text-transform: uppercase;
    @media(max-width:991px){
      font-size: 25px;
    }
    @media(max-width:767px){
      font-size: 20px;
    }
  }
}

.bs-slider-items-wrap {
  .bs-item {
    display: flex !important;
    @media(max-width:640px){
      flex-wrap:wrap;
    }
    .bs-img {
      width: calc(40% - 20px);
      display: flex;
      // margin-right: 20px;
      @media(max-width:767px){
        width: 35%;
        margin:0;
      }
      @media(max-width:640px){
        width: 100%;
        margin:0;
        max-height: 400px;
      }
      img {
        object-fit: cover;
        width: 100%;
      }
    }

    .bs-content {
      width: 60%;
      display: inline-block;
      vertical-align: top;
      text-align: left;
      background-color: #000;
      color: #fff;
      padding: 30px;
      @media(max-width:767px){
        width: 65%;
        margin:0;
        padding: 20px;
      }
      @media(max-width:640px){
        width: 100%;
        margin:0;
        padding: 20px;
      }
      h1 {
        color: #fff;
        font-size: 30px;
        font-weight: 500;
        margin-bottom: 30px;
        margin-top: 30px;
        @media(max-width:991px){
          font-size: 25px;
        }
        @media(max-width:767px){
          font-size: 20px;
          margin-bottom: 20px;
          margin-top: 20px;
        }
        @media(max-width:640px){
          margin-bottom: 5px;
          margin-top: 0px;
        }
        
      }
      p {
        font-size: 20px;
        margin-bottom: 20px;
        line-height: 1.8;
        font-family: "Roboto";
        font-weight: 400;
        @media(max-width:991px){
          font-size: 18px;
        }
        @media(max-width:767px){
          font-size: 14px;
          line-height: 1.5;
        }
      }
      .bs-meta-info {
        margin-top: 30px;
        span {
          font-size: 14px;
          margin-right: 15px;
          svg {
            font-size: 15px;
            height: 16px;
            width: 16px;
          }
        }
      }

      .bs-btn {
        width: 100%;
        margin-top: 30px;
        text-align: right;
        a {
          width: auto;
          color: #000;
          font-size: 16px;
          background-color: #cdaf73;
          padding: 10px 20px;
          font-weight: 500;
          @media(max-width:767px){
            padding: 8px 15px;
            font-size: 14px;
          }
        }
      }
    }
  }
  svg,
  .slick-prev,
  .slick-next {
    font-size: 30px;
    height: 30px;
    width: 30px;
  }
  .slick-prev {
    left: -35px;
    @media(max-width:640px){
      display: none!important;
    }
  }
  .slick-next {
    right: -35px;
    @media(max-width:640px){
      display: none!important;
    }
  }
}
