.contact-us-wrap {
  .cm-container {
    .container-inner {
      box-shadow: #00000061 5px 3px 25px -5px, #0000000a 0px 10px 10px -5px;
      padding: 15px 15px 30px 15px;
      margin-top: 50px;
      margin-bottom: 50px;
      min-height: 450px;
      display: flex;
      flex-wrap: wrap;
      width: 100%;

      .col-left {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 30px;
        @media (max-width: 767px) {
          flex: 0 0 100%;
          max-width: 100%;
          padding: 10px;
        }
        h1 {
          color: #000000d6;
          font-size: 40px;
          span {
            color: #cdaf73;
          }
          @media (max-width: 767px) {
            color: #000000d6;
            font-size: 30px;
            span {
              color: #cdaf73;
            }
          }
        }
        p {
          color: #000000d6;
          font-size: 18px;
          font-weight: 500;
          @media (max-width: 767px) {
            font-size: 16px;
          }
        }
      }

      .col-right {
        flex: 0 0 50%;
        max-width: 50%;
        padding: 30px;

        @media (max-width: 767px) {
          flex: 0 0 100%;
          max-width: 100%;
          padding: 10px;
        }
        .form-wrap {
          .input {
            border: 1px solid grey;
            transition: all 0.15s linear;
            margin-bottom: 0;
            min-height: 35px;
            display: block;
            width: 100%;
            padding: 5px 10px;
            font-size: 16px;
            line-height: 1.2;
            border-radius: 0;
            &:focus {
              outline: none;
              border: 1px solid #cdaf73;
              box-shadow: 0 0 0 3px #f3d28f4d;
            }
          }
          label {
            margin-bottom: 5px;
          }
          button {
            margin-top: 20px;
            font-size: 20px;
            font-weight: 500;
            border-radius: 0;
            padding-top: 5px;
            padding-bottom: 5px;
          }
        }
      }
    }
  }
}
