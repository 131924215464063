/** @format */

.dashboard-header-wrap {
  position: fixed;
  background-color: #fff;
  width: 100%;
  z-index: 99;

  .dh-wrap-inner {
    display: flex;
    align-items: center;

    .dh-col-left {
      width: 280px;
      float: left;
      padding: 20px 20px;
      border-bottom: 1px solid #80808029;
      border-right: 1px solid #80808029;

      @media (max-width: 575px) {
        width: 150px;
        border: none;
      }

      @media (max-width: 460px) {
        width: 100px;
        border: none;
        padding: 15px 10px;
      }

      .dh-col-left-inner {
        .logo-wrap {
          text-align: center;

          img {
            max-width: 80px;
            margin: 0 auto;
            width: 100%;

            @media (max-width: 460px) {
              max-width: 60px;
            }

            @media (max-width: 460px) {
              max-width: 60px;
            }
          }
        }
      }
    }

    .dh-col-right {
      width: calc(100% - 280px);
      float: right;
      padding: 20px 20px;

      @media (max-width: 575px) {
        width: calc(100% - 150px);
      }

      @media (max-width: 460px) {
        width: calc(100% - 80px);
        padding: 10px 10px;
      }

      .dh-col-right-inner {
        text-align: right;

        .dh-notification-wrap {
          display: inline-block;
          margin-right: 10px;
          background-color: #efefefa8;
          padding: 10px 20px;
          border-radius: 20px;
          color: #607d8b;
          font-size: 16px;
          &.notification {
            color: #000;
            position: relative;
            z-index: 999;
            @media(max-width:767px){
              position: static;
            }
            .dh-notification-icon {
              cursor: pointer;
            }
            &:hover .dh-notification-icon {
              transition: 0.15s ease-out;
              color: #cdaf73;
              text-decoration: none;
            }
            svg {
              font-size: 20px;
            }
            .nf-wrap {
              position: absolute;
              right: -50%;
              top: 48px;
              background-color: #fff;
              min-width: 320px;
              padding: 20px 15px;
              border-radius: 6px;
              text-align: left;
              box-shadow: #32325d40 -4px -8px 27px 3px,
                #0000004d 0px 8px 16px -8px;
              max-height: 400px;
              overflow-y: scroll;
              animation: slideDown 0.25s linear;
              z-index: 999;
              @media(max-width:768px){
                right: auto;
                left: 0;
              }
              @media(max-width:767px){
                max-height: 300px;
                max-width: 300px;
                right: 0;
                margin: 0 auto;
                top: 93px;
                min-width: auto;

              }
              
              .top-section {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid #bdb5b5;
                padding-bottom: 10px;
                .btn-black {
                  padding: 4px 10px;
                  font-size: 12px;
                  line-height: 1;
                  border-width: 1px;
                  min-height: auto;
                  border-style: solid;
                  &:focus,
                  &:hover {
                    outline: none;
                    background-color: #212529;
                    border-color: #212529;
                  }
                }
                h6 {
                  margin: 0;
                  font-size: 18px;
                }
              }
              .nf-list {
                padding-top: 10px;
                > ul {
                  margin: 0;
                  padding: 0;
                  li {
                    font-size: 12px;
                    border-bottom: 1px solid #efefef;
                    padding: 7px 0;
                    &:last-child {
                      border-bottom: 0;
                    }
                    p {
                      svg {
                        font-size: 14px;
                        margin-right: 3px;
                      }
                      margin: 0;
                    }
                  }
                }
              }
            }
          }

          @media (max-width: 460px) {
            padding: 5px 5px;
            margin-right: 5px;
          }

          @media (max-width: 375px) {
            font-size: 14px;
          }

          span.dh-notification-icon {
            span {
              margin-left: 8px;
            }
          }
        }

        .dh-profile-menu-wrap {
          display: inline-block;
          background-color: #4caf50db;
          padding: 10px 15px;
          border-radius: 45px;
          color: #fff;

          @media (max-width: 460px) {
            padding: 5px 10px;
          }

          @media (max-width: 375px) {
            font-size: 14px;
          }

          .dh-profile-image-wrap {
            p {
              margin: 0;
              font-size: 16px;
              letter-spacing: 2px;
              font-weight: 700;

              @media (max-width: 375px) {
                font-size: 14px;
              }
            }
          }
        }

        .dh-profile-menu {
          display: inline-block;
          font-size: 16px;
          font-weight: 400;
          color: #0f4061;

          @media (max-width: 375px) {
            font-size: 14px;
          }

          ul.level-1 {
            margin: 0;
            padding: 0;

            li {
              position: relative;
              padding: 10px 15px;
              cursor: pointer;

              @media (max-width: 460px) {
                padding: 10px;
              }

              .dropdown-icon {
                margin-left: 5px;

                svg {
                  transition: all 0.2s linear;
                }
              }

              ul.level-2 {
                visibility: hidden;
                opacity: 0;
                position: absolute;
                transition: all 0.2s linear;
                margin: 0;
                padding: 8px 0;
                position: absolute;
                right: 0;
                top: 50px;
                background-color: #efefef;
                border-radius: 5px;
                min-width: 150px;
                text-align: left;
                transform: translatey(20px);
                box-shadow: 0px 1px 4px 0px #9e9e9ea3;

                li {
                  padding: 5px 15px;

                  a {
                    color: #0f4061;
                    transition: all 0.2s linear;
                    width: 100%;
                  }

                  &:hover a {
                    color: #cdaf73;
                    transform: translateX(5px);
                  }
                }
              }

              &:hover {
                ul.level-2 {
                  visibility: visible;
                  opacity: 1;
                  transform: translatey(0px);
                }

                .dropdown-icon {
                  svg {
                    transform: rotate(180deg);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

@keyframes slideDown {
  0% {
    opacity: 0;
    transform: translateY(40px);
  }
  100% {
    opacity: 1;
    transform: translateY(0px);
  }
}
