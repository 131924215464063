.select-address-wrap {
  padding: 30px 0;
  .sd-inner {
    .title {
      h1 {
        font-size: 24px;
        @media (max-width: 767px) {
          font-size: 18px;
        }
        @media (max-width: 460px) {
          font-size: 16px;
        }
      }
    }

    .sd-listing {
      margin-top: 15px;
      display: flex;
      flex-wrap: wrap;
      .sd-list-item {
        position: relative;
        padding: 20px 15px;
        float: left;
        margin-right: 15px;
        border: 1px dashed #aaa4a4;
        margin-bottom: 30px;
        width: calc(50% - 15px);
        min-height: 150px;
        position: relative;
        transition: all 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
        -webkit-animation: pop-in 0.4s;
        -moz-animation: pop-in 0.4s;
        -ms-animation: pop-in 0.4s;
        transition: all 0.25s linear;
        P {
          margin-bottom: 8px;
          word-break: break-all;
          em {
            font-size: 12px;
            font-weight: 700;
          }
        }
        .addressChoice {
          margin-top: 25px;
          label {
            color: #f0a443de;
            font-size: 14px;
            cursor: pointer;
            span {
              display: none;
              margin-right: 5px;
            }
          }
          input[type="radio"] {
            display: none;
          }
          input[type="radio"]:checked + label {
            span {
              display: inline-block;
            }
            color: #17ca1e;
            font-size: 16px;
          }
        }

        .address-actions {
          text-align: right;
          margin-top: 20px;
          span {
            display: inline-flex;
            margin-left: 8px;
            font-size: 12px;
            width: auto;
            align-items: center;
            cursor: pointer;
            svg {
              margin-right: 5px;
            }
            &.edit {
              &:hover {
                color: green;
              }
            }
            &.delete {
              &:hover {
                color: #ff0404;
              }
            }
          }
        }

        &:hover {
          box-shadow: #06355338 0px 7px 29px 0px;
        }

        &.add-new {
          padding: 0;
          width: 100%;
          text-align: center;
          cursor: pointer;
          img {
            width: 100%;
            max-width: 200px;
            opacity: 0.5;
            transition: all 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
          }
          h2 {
            visibility: visible;
            opacity: 1;
            font-size: 24px;
            transition: all 0.25s cubic-bezier(0.075, 0.82, 0.165, 1);
            margin-bottom: 0;
          }
          &:hover {
            h2 {
              color: #cdaf73;
            }
          }
        }

        @media (min-width: 460px) {
          width: calc(33.33% - 15px);
        }
        @media (min-width: 768px) {
          width: calc(33.33% - 15px);
          min-height: 190px;
        }
        @media (min-width: 991px) {
          width: calc(25% - 15px);
          &:nth-child(5n) {
            // margin-left: 0;
            margin-right: 0;
          }
        }
      }
    }

    .asw-button-wrap {
      margin-top: 30px;
      .btn-black {
        -webkit-animation: pop-in 0.4s;
        -moz-animation: pop-in 0.4s;
        -ms-animation: pop-in 0.4s;
        width: 100%;
        font-size: 18px;
        font-weight: 400;
        padding: 4px 25px;
        border-style: solid;
      }
    }
  }
}

//  AddressModal
.address-modal {
}

body .cm-modal {
  max-width: 80vw;
  .modal-header {
    padding: 0;
    text-align: center;
    display: block;
    border-bottom: 0;
    .modal-title {
      display: block;
      margin-top: 20px;
      img {
        max-width: 60px;
      }
      h3 {
        margin-top: 10px;
        letter-spacing: 1px;
        font-size: 28px;
        text-transform: uppercase;
      }
    }
    button {
      position: absolute;
      top: 20px;
      right: 20px;
    }
  }
  .modal-footer {
    border-top: 0;
    .btn-black {
      font-size: 18px;
      font-weight: 500;
      padding: 4px 25px;
      &.outline {
        background-color: transparent;
        color: #000;
        &:hover,
        &:active {
          background-color: #212529;
          border-color: #212529;
          color: #fff;
        }
      }
    }
  }
  .modal-body {
    .input {
      display: block;
      width: 100%;
      border-radius: 0;
      padding: 5px 10px;
      font-size: 16px;
      min-height: 30px;
      &[aria-readonly="true"] {
        opacity: 0.3;
      }
    }
    input[type="checkbox"] {
      width: 20px;
      margin-top: 0.5px;
      height: 16px;
      border-radius: 0 !important;
      margin-right: 10px;
    }
    input[type="checkbox"] + label {
      margin-left: 5px;
    }
    label {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0px;
    }
  }
}

body .select-user {
  padding-top: 20px;
}

body .select-user {
  .select-inner {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    min-height: 150px;
    .btn-wrap {
      width: 50%;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
      flex-direction: column;
      &:not(:first-child) {
        border-left: 1px solid #8080807d;
      }
      .btn-black {
        color: #fff;
        font-size: 18px;
        font-weight: 400;
        padding: 7px 25px;
        border-width: 1px;
        border-style: solid;
        border-radius: 0;
        margin-bottom: 10px;
      }
      p {
        padding: 0;
        margin: 0;
        font-size: 17px;
        font-weight: 500;
      }
    }
  }
  .modal-header {
    padding-top: 10px;
  }
}

//  Fill address
.fill-address-wrap {
  padding-top: 30px;
  padding-bottom: 50px;
  .fill-address-inner {
    .title {
      h1 {
        font-size: 35px;
        margin-bottom: 20px;
    }
    }
    .input {
      display: block;
      width: 100%;
      border-radius: 0;
      padding: 5px 10px;
      font-size: 16px;
      min-height: 30px;
      &:focus {
        outline: none;
        border: 1px solid #cdaf73;
        box-shadow: 0 0 0 3px #f3d28f4d;
    }
    }
    label{
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0px;
    }
  }
}
