.Newsletter {
  background: #f3f5f9;
  padding: 20px 0;
  position: relative;

  @media (max-width: 767px) {
    text-align: center;
  }

  .newsletter-inner {
    max-width: 767px;
    margin: 0 auto;

    .icon-wrap {
      display: inline-block;
      font-size: 107px;
      line-height: 1;
      position: relative;
      top: 20px;

      @media (max-width: 767px) {
        display: block;
        font-size: 80px;
      }
    }

    .content {
      display: inline-block;
      width: 80%;

      @media (max-width: 767px) {
        display: block;
        width: 100%;
      }

      h2 {
        margin: 0;
        line-height: 1.2;
        font-size: 28px;
      }

      p {
        margin: 0;
      }

      .newsletter-para {
        margin-top: -8px;

        @media (max-width: 767px) {
          margin-top: 0;
        }

      }

      .form-wrap form {
        width: 100%;
        display: FLEX;

        @media (max-width: 767px) {
          margin-top: 15px;
        }

        input {
          width: 80%;
          // min-height: 45px;

        }

        .btn-brown {
          border-radius: 0;
          border-style: solid;
          font-size: 30px;
          font-weight: 700;
          line-height: 1;
          background-color: #212529;
          border-color: #212529;
          padding: 0;

          @media (max-width: 767px) {
            font-size: 20px;
          }
        }
      }
    }
  }
}