/** @format */

@import "../../sass/abstracts/_Variable.scss";

.footer-main-wrap {
  background-color: $footer-copyright-bg-color;

  @media (max-width: 1200px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  .f-menu-wrap {
    padding-bottom: 50px;
    // border-top: 1px solid #063553;
    padding-top: 50px;
    color: #fff;

    @media (max-width: 575px) {
      padding-top: 25px;
      margin-top: 40px;
      margin-bottom: 40px;
      padding-top: 25px;
      padding-left: 0px;
      padding-right: 0px;
    }

    .f-menu-col {
      display: inline-block;
      width: 25%;
      vertical-align: top;
      padding-right: 15px;
      padding-left: 15px;

      &.cm-col-1 {
        padding-right: 35px;
      }

      @media (max-width: 575px) {
        width: 50%;

        &.cm-col-1,
        &.cm-col-2 {
          margin-bottom: 30px;
        }
      }

      h3 {
        font-size: 16px;
        font-family: "Roboto";
        line-height: 1.75;
        color: #fff;
        margin-bottom: 10px;
        font-weight: 700;
      }

      .f-menu {
        ul {
          li {
            width: 100%;
            padding-top: 8px;
            padding-bottom: 8px;

            a {
              color: #fff;
            }

            @media (max-width: 575px) {
              padding-top: 4px;
              padding-bottom: 4px;
              font-size: 12px;
            }
          }
        }
      }

      .social-icons-wrap {
        position: relative;
        left: -8px;
        margin-top: 10px;

        .social-item {
          display: inline-block;
          vertical-align: middle;
          cursor: pointer;

          img {
            @media (max-width: 1024px) {
              max-width: 45px;
            }

            @media (max-width: 767px) {
              max-width: 35px;
            }
          }
        }
      }
    }

    .cm-col-1 {
      .logo-wrap {
        margin-bottom: 20px;

        img {
          width: 100px;
        }
      }

      .content {
        p {
          font-size: 12px;
        }

        .btn-black {
          padding: 0;
          font-size: 16px;
          border-radius: 4px;
          background-color: transparent;
          color: #cdaf73;
          font-weight: 700;

          span {
            transition: all .25s linear;
            display: inline-block;
            font-size: 17px;
          }

          &:hover span {
            transform: translateX(5px);
          }
        }
      }
    }
  }


}

.copyright-wrap {
  padding: 15px 20px;
  background-color: $footer-copyright-bg-color;
  text-align: center;
  color: $footer-copyright-color;

  p {
    margin-bottom: 0;
  }
}

.f-payment-main-wrap {
  ul {
    text-align: center;
    margin-bottom: 0;

    li {
      display: inline-block;
      vertical-align: middle;

      &:not(:last-child) {
        margin-right: 16px;
      }

      @media (max-width: 575px) {
        &:first-child {
          width: 100%;
          text-align: center;
          margin-top: 8px;
        }
      }

      .payment-option {
        width: 119px;


        @media (max-width: 991px) {
          width: 80px;
        }

        @media (max-width: 767px) {
          width: 60px;
        }

        @media (max-width: 575px) {
          width: 45px;
        }

        p {
          text-align: center;
          margin-bottom: 0;

          @media (max-width: 575px) {
            font-size: 8px;
            font-weight: 500;
          }
        }
      }

      img {
        max-width: 70px;
        width: 100%;
      }

      h3 {
        color: #063553;
        margin: 0;

        @media (max-width: 575px) {
          font-size: 14px;
        }
      }
    }
  }
}