.vendor-dashboard-summary-warp {
  // padding: 25px;
  // @media(max-width:460px){
  //   padding:25px 15px;
  // }

  .vds-inner-warp {
    .vds-top-section {
      h1 {
        font-size: 35px;
      }
    }

    .vds-row-2 {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      margin-top: 30px;
      justify-content: center;
      @media (max-width: 991px){
        flex-direction: column;
      }
      .vds-item {
        min-height: 130px;
        background-color: #fff;
        padding: 20px;
        float: left;
        margin-right: 20px;
        border-radius: 12px;
        margin-bottom: 20px;
        position: relative;

        &.products {
          background-color: #a5ffa942;

          .vds-item-icon {
            svg {
              color: #22d129;
            }
          }
        }

        &.orders {
          background-color: #217ff333;

          .vds-item-icon {
            svg {
              color: #217ff3;
            }
          }
        }

        &.return-order {
          background-color: #21b1f333;

          .vds-item-icon {
            svg {
              color: #21b1f3;
            }
          }
        }

        &.canceled-orders {
          background-color: #ff000026;

          .vds-item-icon {
            svg {
              color: #ff0000c7;
            }
          }
        }

        &.total-earning {
          background-color: #f0f32133;

          .vds-item-icon {
            svg {
              color: #fdaa11;
            }
          }
        }

        &.received-payouts {
          background-color: #d3d4d348;

          .vds-item-icon {
            svg {
              color: #7c7a77;
            }
          }
        }

        @media (min-width: 992px) {
          width: calc(33.33% - 20px);

          &:nth-child(3n) {
            margin-right: 0;
          }
        }
        @media (max-width: 991px) {
            width: 100%;
  
            &:nth-child(2n) {
              margin-right: 0;
            }
          }

          @media (max-width: 460px) {
            width: calc(100%);
            margin-right: 0px;
            min-height: 80px;
            &:nth-child(2n) {
              margin-right: 0px;
            }
          }

        .vds-item-content {
          span {
            font-size: 25px;
            font-weight: bold;
          }

          p {
            margin-top: 10px;
            color: #9e9e9e;
            @media(max-width:460px){
              margin-bottom: 0;
            }
          }
        }

        .vds-item-icon {
          position: absolute;
          top: 20px;
          right: 20px;
          max-width: 100px;

          svg {
            font-size: 60px;
            opacity: 0.9;
            color: #141414;
            @media(max-width:460px){
              font-size: 45px;
            }
          }
        }
      }
    }

    .vds-row-3 {
      margin-top: 20px;

      .vds-row-heading {
        background-color: #fff;

        h2 {
          margin: 0;
          text-align: center;
          font-size: 20px;
          padding-top: 15px;
        }
      }
    }

    .vds-row-4 {
      background-color: #fff;
      margin-top: 34px;

      .vds-row-4-inner {
        padding: 20px;

        .vds-row-4-heading {
          h2 {
            margin: 0;
            font-size: 18px;
          }
        }

        .vds-row-4-orders {
          margin-top: 20px;
          @media(max-width:460px){
            overflow: scroll;
          }

          table {
            width: 100%;

            thead,
            tbody {
              tr {
                border-bottom: 1px solid #8080802e;

                th,
                td {
                  padding: 12px 8px;
                }
              }
            }
          }
        }
      }
    }

    .vds-row-3.payout-wrap {
      margin-top: 35px;
    }

    .vds-row-6 {
      background-color: #fff;
      margin-top: 34px;

      .vds-row-6-inner {
        padding: 20px;

        .vds-row-6-heading {
          h2 {
            margin: 0;
            font-size: 18px;
          }
        }

        .vds-row-content {
          margin-top: 20px;

          .vds-item {
            display: flex;
            flex-wrap: wrap;
            margin-bottom: 30px;
            padding-bottom: 10px;
            border-bottom: 1px solid #8080809e;

            .vds-rap-img {
              width: 15%;
              @media (max-width: 575px){
                width: 100%;
                text-align: center;
                    padding: 15px 0;
              }
              img {
                max-width: 120px;
                width: 100%;
                object-fit: cover;
              }
            }
            .vds-rap-content {
              width: 70%;
              padding: 0 15px;
              a {
                font-size: 20px;
                font-weight: 500;
                margin-bottom: 5px;
              }
              p {
                font-size: 12px;
            }
              .product-meta {
                margin-top: 8px;
                padding-top: 10px;
                border-top: 1px solid #80808047;
                p {
                  margin-bottom: 2px;
              }
            }
             
            }
            .vds-rap-price {
              display: flex;
              width: 15%;
              align-items: center;
              justify-content: center;
              flex-direction: column;
              span {
                background-color: #59d183;
                padding: 3px 5px;
                border-radius: 5px;
                color: #fff;
                display: inline-block;
                font-size: 14px;
                margin-bottom: 5px;
                &+span{
                  background-color: #e24747;
                  text-decoration: line-through;
                }
              }
            }
          }
        }
      }
    }

    @media(max-width: 768px){
    padding-top: 40px;
    }
  }
}
