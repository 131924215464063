.order_management {
  .order_table {
    table {
      tbody {
        tr {
          td {
            // text-align: center;
          }
        }
      }
    }
  }
}

.order_details {
  .box-size {
    padding: 30px;

    @media (max-width: 767px) {
      padding: 30px 20px;
    }
  }

  .order_details_content {
    .details_title {
      padding: 0.75rem 8px;
      margin-bottom: 0;
      background-color: #f3f4f6;
      border-bottom: 1px solid #f3f4f6;
      font-weight: 600;
      font-size: 18px;

      @media (max-width: 991px) {
        font-size: 16px;
        padding-left: 0;
        padding-right: 0;
      }

      ul {
        display: flex;
        justify-content: space-between;
        margin: 0;
        padding: 0;
        align-items: center;

        li {
          a {
            color: #007bff;

            &:hover {
              color: #cdaf73;
            }
          }

          &:last-child {
            a {
              outline: none;
              border: none;
              margin: 0 5px;
              padding: 4px 10px;
              border-radius: 5px;
              font-size: 12px;
              background: #fff;
              font-weight: 500;
              color: inherit;
              color: #fff;
              background-color: #6c757d;
              border-color: #6c757d;


              &:hover {
                color: #fff;
                background-color: #5c636a;
                border-color: #565e64;
              }

              //   border-radius: 20px;
              //   padding: 4px 20px;
              //   width: auto;
              //   margin-left: 0;
              //   margin-right: 0;
              //   min-width: unset;
              //   font-style: italic;
              //   box-shadow: none;
              //   color: #343a40;
              //   background-color: rgba(255, 54, 1, 0.7607843137254902);
              //   position: relative;
              //   top: -1px;
              //   font-size: 13px;
              //   color: #fff;
              //   letter-spacing: 1px;
            }
          }
        }
      }
    }

    h3 {
      margin: 30px 0 10px;
      font-size: 25px;
      text-transform: uppercase;

      @media (max-width: 767px) {
        font-size: 20px;
      }
    }

    .order_id {
      margin: 15px 0;

      ul {
        width: 100%;
        max-width: 530px;
        margin: 0;

        li {
          display: flex;
          justify-content: space-between;
          padding-bottom: 10px;
          text-transform: capitalize;
        }
      }
    }

    .detail_table {
      width: 100%;
      overflow: auto;

      table {
        width: 100%;
        font-size: 12px;
        margin: 15px 0;
        min-width: 600px;

        th {
          padding: 7px 0;
          font-size: 16px;
        }

        tr {
          border-top: 1px solid #aaa;

          &:last-child {
            border-bottom: 1px solid #aaa;
          }

          td {
            padding: 5px 0;

            span {
              display: block;
              font-weight: 400;
            }

            &:first-child {
              font-weight: bold;
              text-transform: uppercase;
            }

            p {
              margin-bottom: 5px;

              em {
                font-size: 12px;
                font-weight: 400;
              }
            }
          }
        }
      }
    }

    ul.bill_summery {
      text-align: right;
      padding: 0 20px;

      @media (max-width: 767px) {
        padding: 20px 0;
      }

      li {
        margin-bottom: 5px;

        strong {
          padding-right: 20px;

          &:last-child {
            padding: 0;
          }
        }
      }
    }

    .order_address {
      ul {
        li {
          padding: 5px;
          line-height: 1.5;

          strong {
            display: inline-block;
            max-width: 140px;
            width: 100%;

            @media (max-width: 767px) {
              max-width: 100%;
            }
          }
        }
      }
    }
  }
}

// Cancel Order Modal
.cancel-order-inner .modal-header {
  padding: 10px 16px;
  background-color: #ff0000bd;
  color: #fff;
}

.cancel-order-inner .modal-header .close {
  color: #fff;
  opacity: 1;
  margin: 0;
  padding: 5px;
}

.cancel-order-inner .modal-header .close:hover {
  opacity: 1;
}

.cancel-order-inner .modal-body {
  font-size: 15px;
  font-weight: 500;
}

.cancel-order-inner .modal-body .cancel-reason-wrap {
  margin-top: 20px;
}

.cancel-order-inner .modal-body .cancel-reason-wrap textarea {
  cursor: pointer;
  border: 1px solid grey;
  transition: all 0.15s linear;
  margin-bottom: 0;
  display: block;
  width: 100%;
  padding: 5px;
}

textarea {}

.cancel-order-inner .modal-body .cancel-reason-wrap label {
  display: block;
  font-size: 14px;
  margin: 0;
  margin-bottom: 5px;
  font-weight: 500;
  color: #005195;
  color: #212529ba;
}

.cancel-order-inner .modal-body .cancel-reason-wrap textarea:focus {
  outline: none;
  outline: none;
  border: 1px solid #00448d;
  box-shadow: 0 0 2px 0 #00448d5c;
}

.cancel-order-inner .modal-footer {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 10px 11px;
  border-top: 0;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}

.cancel-order-inner .modal-footer button {
  background-color: transparent;
  color: #3e3838;
  border: none;
  margin: 0;
  font-weight: 700;
  text-transform: uppercase;
  font-size: 13px;
  transition: all 0.2s linear;
}

.cancel-order-inner .modal-footer button.btn-danger {
  color: #ff0000bd;
  margin-left: 5px;
}

.cancel-order-inner .modal-footer button:hover {
  background-color: #80808026;
}

.cancel-order-inner .modal-body .cancel-reason-wrap label span {
  margin-left: 5px;
  font-size: 11px;
  font-style: italic;
}

.cancel-order-inner .modal-header .h4 {
  color: #fff;
}

.cancel-watermark {
  border-radius: 20px;
  padding: 4px 20px;
  width: auto;
  margin-left: 0;
  margin-right: 0;
  min-width: unset;
  font-style: italic;
  box-shadow: none;
  color: #343a40;
  background-color: #ff3601c2;
  position: relative;
  top: -1px;
  font-size: 13px;
  color: #fff;
  letter-spacing: 1px;
}

//  New Order list css
.order_wrap {
  border: 1px solid #80808069;
  border-radius: 10px;
  margin-top: 35px;
  overflow: hidden;

  .order-inner {}

  .top-section {
    padding: 10px 20px;
    background-color: #cfcdcd70;

    p {
      margin: 0;
      font-size: 14px;
      line-height: 1.4;
      font-weight: 400;
      color: #575252;

      @media (max-width: 991px) {
        font-size: 12px;
      }
    }

    a {
      color: #cdaf73;

      &:hover {
        color: #cdaf73;
        text-decoration: underline;
      }
    }

    .left {
      float: left;
      width: 50%;

      @media (max-width: 991px) {
        width: 100%;
      }

      div {
        display: inline-block;

        &:not(:last-child) {
          margin-right: 35px;
        }
      }

      .Shipping-info {
        visibility: hidden;
        opacity: 0;
        display: none;
      }
    }

    .right {
      float: left;
      width: 50%;
      text-align: right;

      @media (max-width: 991px) {
        width: 100%;
        text-align: left;
        margin-top: 5px;
        font-size: 10px;
      }

      .inner {
        span {
          padding-left: 8px;
          padding-right: 8px;
        }
      }
    }
  }

  .middle-section {
    max-height: 345px;
    overflow-y: auto;

    @media (max-width: 991px) {
      max-height: 466px;
    }

    @media (max-width: 767px) {
      max-height: 418px;
    }

    .middle-inner {
      padding: 15px 10px;
      padding-bottom: 20px;
      border-bottom: 1px solid #80808069;
      background-color: #fff;

      .left {
        width: 70%;
        display: inline-block;
        float: left;

        @media (max-width: 991px) {
          width: 100%;
        }

        .product-Info {
          div {
            display: inline-block;
            vertical-align: top;
            margin-left: 20px;
          }

          img {
            width: 80px;
            height: 80px;
            object-fit: cover;

            @media (max-width: 767px) {
              width: 70px;
              height: 70px;
            }
          }

          .title {
            h4 {
              font-size: 18px;

              @media (max-width: 767px) {
                font-size: 18px;
              }
            }
          }
        }
      }

      .right {
        width: 30%;
        display: inline-block;
        float: left;
        text-align: center;

        @media (max-width: 991px) {
          width: 100%;
        }

        .actions {
          text-align: center;

          @media (max-width: 991px) {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            text-align: left;
            margin-top: 25px;
          }

          @media (max-width: 767px) {
            margin-top: 15px;
          }

          .btn-black {
            background-color: transparent;
            color: #000;
            border-width: 1px;
            display: block;
            padding: 3px 30px;
            font-size: 12px;
            line-height: 1;
            min-width: 180px;
            margin-left: auto;
            margin-right: auto;
            border-style: solid;

            &.--disabled {
              opacity: 0.5;
            }

            &:not(:last-child) {
              margin-bottom: 7px;
            }

            @media (max-width: 991px) {
              display: inline-block;
              margin-bottom: 7px;
              width: calc(50% - 10px);
              margin-left: 10px;
              min-width: auto;
            }

            @media (max-width: 575px) {
              padding: 3px 10px;
            }
          }
        }
      }
      .refund-note {
        text-align: left;
        display: block;
        margin: 0;
        margin-top: 20px;
        font-weight: 500;
        font-style: italic;
        color: #f44336ed;
        font-size: 12px;
        &.--success {
          color: #008000d6;
      }
      span {
        font-size: 14px;
        font-weight: 700;
    }
    }   
    }
  }
}

.pagination-wrap {
  margin-top: 50px;

  .page-item {
    .page-link {
      border: none;
      border-radius: 50%;
      background-color: transparent;
    }

    &.active {
      .page-link {
        background-color: #cdaf73;
      }
    }

    &.disabled {
      opacity: 0.6;
    }
  }
}

//  modal styling
.write-review.cancel-order-inner .modal-header {
  border-radius: 0;
  color: #000;
  background-color: #063553eb;
}

.write-review.cancel-order-inner .modal-content .btn-close:hover:after {
  color: #fff;
}

.write-review.cancel-order-inner .modal-content .btn-close:after {
  color: #fff;
}

.write-review.cancel-order-inner .modal-footer button.btn-danger {
  color: #cdaf73;
}

.rating-star {
  text-align: center;
}

.write-review.cancel-order-inner .modal-body .rating-star {
  width: 100%;
  margin-top: 20px;
}

.write-review.cancel-order-inner .modal-body .rating-star>div {
  width: 100%;
}

.write-review.cancel-order-inner .modal-body .rating-star>div span {
  font-size: 45px !important;
}


// Update Order Details Modal 

.update-order-info-wrap {
  .modal-header {
    background-color: #063553;

    .btn-close {
      color: #fff;
    }
  }

  .modal-body {
    form {
      h4 {
        margin-bottom: 20px;
      }

      .form_field {
        width: 100%;
        margin-bottom: 15px;

        label {
          display: block;
          margin-bottom: 5px;
          font-size: 14px;
          color: #2d3436;
          text-transform: capitalize;
        }

        .input {
          width: 100%;
          min-height: 30px;
          font-size: 14px;
          padding: 5px 10px;
        }
      }
    }
  }

  .modal-footer {
    .btn-success {
      color: #fff;
      background-color: #198754;
      border-color: #198754;

      &:hover {
        color: #fff;
        background-color: #157347;
        border-color: #146c43;
      }
    }

    .btn-secondary {
      color: #fff;
      background-color: #6c757d;
      border-color: #6c757d;
      margin-right: 10px;

      &:hover {
        color: #fff;
        background-color: #5c636a;
        border-color: #565e64;
      }
    }

  }
}