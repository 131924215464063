.product-variant-wrap {
  .pv-inner {
    .title {
      span {
        margin-left: 5px;
        font-size: 14px;
        font-style: italic;
        font-weight: 500;
      }
    }

    .pv-content {
      .menu-options {
        ul {
          li {
            position: relative;
            text-align: center;
            a {
              white-space: nowrap;
              font-weight: 700;
              width: 100%;

              .icon {
                font-size: 20px;
              }
              .text {
                transition: all 0.25s linear;
                color: #cdaf73;
                opacity: 0;
                visibility: hidden;
                font-size: 0;
                text-align: center;
                width: 0px;
                overflow: hidden;
              }
            }
            &:hover {
              a {
                .text {
                  width: 100%;
                  overflow: visible;
                  opacity: 1;
                  font-size: 100%;
                  visibility: visible;
                  transition: all 0.25s linear;
                }
              }
            }
          }
        }
      }
    }
  }
}
