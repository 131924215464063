.view-all-variant{

    .vav-inner{

        .vav-content{
            .menu-options {
                ul {
                    li {
                        position: relative;
                        text-align: center;
                        &.delete-icon{
                            a{
                                color: #ff0000a1;
                                .text{
                                    color: #ff0000a1;
                                }
                                .icon{
                                    color: #ff0000a1;
                                }
                            }
                            
                        }
                        a {
                            white-space: nowrap;
                            font-weight: 700;
                            width: 100%;
                            cursor: pointer;
                            text-decoration: none;
                            .icon {
                                font-size: 20px;
                                color:#000;
                            }
                            .text {
                                transition: all .25s linear;
                                color: #cdaf73;
                                opacity: 0;
                                visibility: hidden;
                                font-size: 0;
                                text-align: center;
                                width: 0px;
                                overflow: hidden;
                            }
                        }
                        &:hover {
                            a {
                                .icon{
                                    color: #cdaf73;
                                }
                                .text {
                                    width: 100%;
                                    overflow: visible;
                                    opacity: 1;
                                    font-size: 100%;
                                    visibility: visible;
                                    transition: all .25s linear;
                                }
                            }
                        }
                        &.delete-icon:hover{
                            a{
                                
                                .icon{
                                    color: #ff0000a1;
                                }
                            }
                            
                        }
                    }
                }


            }
        }
    }
}