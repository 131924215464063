.c-listing {
  .inner {
    // display: flex;
    flex-wrap: wrap;
  }
  .item {
    width: 20%;
  }
  .item-inner {
    padding: 10px 15px;
    // margin-bottom: 25px;
    h5{
        text-align: center;
        margin-top: 5px;
    }
    a {
      display: block;
      text-align: center;
    }
   
    img {
      box-shadow: #63636333 0px 2px 8px 0px;
      height: 150px;
      width: 100%;
      border-radius: 5px;
      transition: all 0.3s linear;
      object-fit: cover;
      width: 190px;
    }
    &:hover img {
      transform: scale(1.05);
    }
  }
 
}
.c-listing-wrap{
  .container {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .content-section {
    width: 80%;
  }
  .adv-section {
    width: calc(20% - 40px);
    margin-left: 40px;
    img:not(last-child) {
      margin-bottom: 25px;
    }
    
  }
}

