/** @format */

.vendor-listing-wrap {
  .banner {
    text-align: center;
    min-height: 200px;
    background-color: #9e9e9e30;
    display: flex;
    justify-content: center;
    width: 100%;
    align-items: center;

    h1 {
      margin-bottom: 0;
      padding: 0;
      line-height: 1.2;
    }

    p {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 0;
    }
  }

  .vls-listing {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;
    margin-top: 90px;
    margin-top: 90px;
    @media (max-width: 768px) {
      margin-top: 60px;
      margin-top: 60px;
    }
    .item {
      flex: 30% 1 0;
      box-shadow: rgba(17, 17, 26, 0.1) 0px 1px 0px,
        rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 48px;
      margin: 10px;
      transition: all 0.25s linear;
      &:hover {
        transform: translate(-0px, -8px);
      }
      @media (max-width: 768px) {
        flex: 100% 1 0;
      }

      .item-content {
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        min-height: 250px;
        position: relative;
        color: #fff;

        &:after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: #06355399;
        }

        .item-content-inner {
          padding: 15px;
          position: relative;
          z-index: 2;
          opacity: 0.9;

          h2 {
            font-size: 25px;
            line-height: 1;
            margin-bottom: 8px;
            color: #fff;
          }

          p {
            margin: 0;
            font-size: 15px;
            padding: 0;
            margin-bottom: 8px;
            line-height: 1;
            color: #fff;

            svg {
              font-size: 18px;
            }
          }

          a {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
          }
        }
      }

      .item-bottom {
        position: relative;
        padding: 16px;

        img {
          width: 66px;
          height: 66px;
          position: absolute;
          top: -39px;
          right: 10px;
          border-radius: 50%;
          object-fit: cover;
        }

        h2 {
          margin: 0;
          padding: 0;
        }

        p {
          margin: 0;
          padding: 0;
        }

        a {
          font-size: 20px;
          font-weight: 700;
        }
      }
    }
  }

  .vlw-inner .no-vendor {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 400px;

    h1 {
      color: #ff00009e;
    }
  }

  .vls-pagination-wrap {
    margin-top: 60px;
    margin-bottom: 60px;

    .page-item.disabled {
      opacity: 0.6;
    }

    .page-link {
      border: none;
      border-radius: 50%;
    }

    .page-item.active .page-link {
      background-color: #cdaf73;
    }
  }
}
