.view-all-btn {
  font-size: 18px;
  font-weight: 500;
  padding: 5px 8px;
  background-color: #cdaf73;
  border-color: #cdaf73;
  transition: all 0.15s linear;
  color: #fff;
  border-radius: 5px;

  &:hover {
    color: #ffff;
    background-color: #103d5a;
  }
}

.product_card {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;

  @media (max-width: 767px) {
    justify-content: center;
  }

  .card_content {
    margin: 10px 10px;
    text-align: left;
    width: 100%;
    max-width: 200px;
    // box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px,
    //   rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
    flex-wrap: wrap;
    align-items: end;
    display: flex;
    overflow: hidden;
    max-width: 18%;
    padding: 10px;
    position: relative;

    a {
      width: 100%;
      text-align: center;
    }

    img {
      max-width: 180px;
      width: 100%;
      max-height: 158px;
      height: 100%;
      object-fit: contain;
      margin: 0 auto;
      transition: 0.3s ease;
    }

    .out-of-stock {
      position: absolute;
      top: 46px;
      left: -34px;
      transform: rotate(270deg);
    }

    .out-of-stock {
      background-color: #f60000ba;
      border-color: #f60000ba;
      color: #fff;
      padding: 3px 8px;
      z-index: 9;
      font-weight: 500;
    }

    @media (max-width: 700px) {
      max-width: 45%;
    }

    @media (max-width: 480px) {
      max-width: 270px;
    }

    .img_wrap {
      width: 100%;
      height: 135px;
      overflow: hidden;

      a {
        width: 100%;
        height: 100%;

        img {
          width: 100%;
          max-width: 100%;
          transition: all 0.3s linear;
          margin: 0 auto;
          display: block;
          object-fit: contain;
          height: 100%;
        }
      }
    }

    .pc-content {
      padding: 10px;
      width: 100%;
      text-align: center;

      a {
        display: block;
        text-align: center;

        h3 {
          font-size: 14px;
          line-height: 1.3;
          padding: 1px 0;
          margin-top: 14px;
          font-weight: bold;
          text-align: center;
        }
      }

      .pc-review {
        // display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
      }
      .star-icon {
        display: flex;
        flex-direction: column;
        align-items: center;
      }

      P {
        margin: 0;

        span {
          padding: 5px;
          display: inline-block;

          del {
            opacity: 0.6;
            color: red;
          }

          &:first-child {
            font-weight: bold;
            font-size: 16px;
          }

          svg {
            position: relative;
            top: -2px;
          }
        }
      }
    }

    &:hover {
      img {
        transform: scale(1.04);
      }
    }

    .button {
      width: 100%;
      text-align: center;

      button {
        font-size: 20px;

        &:hover {
          color: #1a4766;
        }
      }
    }
  }

  &.list-view {
    .card_content {
      max-width: unset;
      display: flex;
      flex-wrap: nowrap;
      align-items: flex-start;
      min-height: 180px;
      overflow: hidden;
      align-items: center;

      @media (max-width: 575px) {
        min-height: 150px;
        min-height: auto;
        padding: 10px;
        flex-wrap: wrap;
        justify-content: center;
      }

      .img_wrap {
        width: 100%;
        max-width: 190px;
        padding: 10px;

        @media (max-width: 575px) {
          max-width: 200px;
        }

        img {
          max-width: 100%;
          object-fit: contain;

          // @media (max-width: 575px) {
          //   width: 110px;
          //   height: 110px;
          //   padding: 0;

          // }

          // @media (max-width: 575px) {
          //   width: 90px;
          //   height: 90px;
          // }
        }
      }

      .pc-content {
        width: calc(100% - 220px);
        text-align: left;

        @media (max-width: 575px) {
          width: 100%;
          padding-top: 0;
          padding-bottom: 0;
          text-align: center;
          padding: 10px 0;
        }

        a {
          text-align: left;

          @media (max-width: 575px) {
            text-align: left;
          }

          h3 {
            font-size: 16px;
            margin-top: 0;
            padding-top: 0;
            padding-bottom: 0;
            text-align: left;
          }
        }

        .pc-review {
          justify-content: flex-start;
        }
      }

      .button {
        width: auto;

        @media (max-width: 575px) {
          width: 100%;
          text-align: center;
        }
      }
    }
  }
}
