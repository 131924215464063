.loading-wrap{
    background-color: #fff;
    justify-self: center;
    justify-items: center;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    text-align: center;
    z-index: 9999;
    height: 100%;
    width: 100%;
    top: auto;
    display: flex;
    justify-content: center;
    // align-items: center;
    overflow: hidden;
    cursor: progress;
    .spinner-border {
        margin-top: 200px;
    }
 
}