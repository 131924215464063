@mixin screen-break-point($point){
    @if $point==tablet{
        @media screen and(max-width:$tablet-Size){
            @content;
        }
    }
}

// @mixin break-point($point) {
// 	@if $point == landscapeMobile {
// 		@media screen and (min-width: 600px) {
// 			@content;
// 		}
// 	}
// 	@else if $point == tablet {
// 		@media screen and (min-width: 768px) {
// 			@content;
// 		}
// 	}
// 	@else if $point == largeTablet {
// 		@media screen and (min-width: 992px) {
// 			@content;
// 		}
// 	}
// 	@else if $point == miniDesktop {
// 		@media screen and (min-width: 1025px) {
// 			@content;
// 		}
// 	}
// 	@else if $point == Desktop {
// 		@media screen and (min-width: 1200px) {
// 			@content;
// 		}
// 	}
// 	@else if $point == largeDesktop {
// 		@media screen and (min-width: 1440px) {
// 			@content;
// 		}
// 	}
// 	@else if $point == mobile {
// 		@media screen and (max-width: 767px) {
// 			@content;
// 		}
// 	}
// 	@else if $point == ie {
// 		@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
// 			@content;
// 		}
// 	}
// }


@mixin BoxShadow{
    box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 36px 0px,
    rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
    padding: 20px 15px;
}