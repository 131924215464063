/**
 * /* Works on Firefox
 *
 * @format
 */

* {
  scrollbar-width: thin;
  scrollbar-color: #063553 #fff;
}

/* Works on Chrome, Edge, and Safari */
// *::-webkit-scrollbar {
//   width: 10px;
// }

// *::-webkit-scrollbar-track {
//   background: #aaa;
// }

// *::-webkit-scrollbar-thumb {
//   background-color: #063553;
//   border-radius: 20px;
//   border: 1px solid #063553;
// }

.banner-btn-1 {
  color: $banner1-btn-color;
  background-color: $banner1-btn-bg-color;
  border: $banner1-btn-border-width $banner1-btn-border-style
    $banner1-btn-border-color;
  padding: $banner1-btn-vertical-padding $banner1-btn-horizontal-padding;
  border-radius: $banner1-btn-border-radius;
}

.cm-button {
  padding: 12px 20px;
  width: auto;
  cursor: pointer;
  transition: all 0.2s linear;
}

.btn-brown {
  font-size: 18px;
  font-weight: 500;
  padding: 10px 25px;
  background-color: #cdaf73;
  border-color: #cdaf73;
  transition: all 0.15s linear;
  color: #fff;
  border-radius: 5px;

  &:hover {
    background-color: #212529;
    border-color: #212529;
  }
}

.btn-black {
  font-size: 18px;
  font-weight: 500;
  padding: 10px 25px;
  background-color: #212529;
  border-color: #212529;
  transition: all 0.2s linear;
  color: #fff;
  border-radius: 0px;

  &:hover {
    background-color: #cdaf73;
    border-color: #cdaf73;
    color: #fff;
  }
}

.input {
  color: #3a3a3a;
  border: 1px solid #000;
  font-size: 18px;
  min-height: 55px;

  @media (max-width: 991px) {
    font-size: 14px;
  }

  &:focus {
    outline: none;
    border: 1px solid #cdaf73;
    box-shadow: 0 0 0 3px #f3d28f4d;
  }
}

input[type="checkbox"],
input[type="radio"] {
  display: inline-block;
  cursor: pointer;
}

label {
  font-size: 16px;
  font-weight: 500;

  @media (max-width: 991px) {
    font-size: 14px;
    margin-bottom: 5px;
  }
}

form {
  .row {
    @media (max-width: 991px) {
      flex-direction: column;
      margin-bottom: 0 !important;

      .col {
        margin-bottom: 20px;
      }
    }
  }
}

.container {
  width: 100%;
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

body {
  .modal-fullscreen {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }

  .modal-content {
    height: 100%;
    border: none;
    border-radius: 0;

    .btn-close {
      border: none;
      // padding: 11px;
      font-size: 20px;
      font-weight: 700;
      background-color: transparent;
      display: block;

      &:after {
        content: "X";
        transition: all 0.025s linear;
      }

      &:hover {
        &:after {
          color: #cdaf73;
        }
      }
    }
  }

  .modal-backdrop {
    background-color: #343a62;
  }
}

.spacing {
  padding: 40px 0;
}

.pcw-pagination {
  margin-top: 50px;

  .page-item {
    .page-link {
      border: none;
      border-radius: 50%;
    }

    &.active {
      .page-link {
        background-color: #cdaf73;
      }
    }

    &.disabled {
      opacity: 0.6;
    }
  }
}

.support-wrap {
  position: fixed;
  top: 50%;
  transform: translateY(-50%) rotate(270deg);
  right: -44px;
}

.support-wrap .btn-black {
  padding: 10px 17px;
  font-size: 14px;
  letter-spacing: 2.5px;
  line-height: 1;
  text-transform: UPPERCASE;
}

.box-size {
  padding: 25px 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

@media (min-width: 576px) {
  body .modal-dialog {
    max-width: 768px;
    margin: 1.75rem auto;
  }
}

.animate-pop-in {
  -webkit-animation: pop-in 0.4s;
  -moz-animation: pop-in 0.4s;
  -ms-animation: pop-in 0.4s;
}

@-webkit-keyframes pop-in {
  0% {
    opacity: 0;
    -webkit-transform: scale(0.5);
  }

  100% {
    opacity: 1;
    -webkit-transform: scale(1);
  }
}

@-moz-keyframes pop-in {
  0% {
    opacity: 0;
    -moz-transform: scale(0.5);
  }

  100% {
    opacity: 1;
    -moz-transform: scale(1);
  }
}

@keyframes pop-in {
  0% {
    opacity: 0;
    transform: scale(0.5);
  }

  100% {
    opacity: 1;
    transform: scale(1);
  }
}

.support-form {
  position: fixed;
  width: auto;
  top: 29%;
  right: -420px;
  max-width: 375px;
  width: 100%;
  min-width: 375px;
  background-color: #fff;
  padding: 25px 15px;
  border: 1px solid grey;
  border-right: none;
  box-shadow: rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px,
    rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px,
    rgba(0, 0, 0, 0.07) 0px 16px 16px;
  transition: all 0.4s ease-in;
  opacity: 0;
  &.-open {
    right: 0;
    opacity: 1;
    transition: all 0.4s ease-in;
  }
  .btn-black.close-btn {
    font-size: 14px;
    letter-spacing: 2.5px;
    line-height: 1;
    text-transform: UPPERCASE;
    top: 52.5px;
    transform: translateY(-50%) rotate(270deg);
    left: -69px;
    position: absolute;
    z-index: -1;
    border: 0;
  }
}

.support-form {
  .input {
    padding: 15px 15px;
    font-size: inherit;
    min-height: auto;
    border-radius: 0;
    margin-bottom: 15px;
  }
  label {
    margin-bottom: 0;
  }
  .btn-brown {
    font-size: 14px;
    line-height: 1;
    text-transform: UPPERCASE;
    background-color: #cdaf73;
    border-color: #cdaf73;
    color: #fff;
    margin-top: 20px;
    border: 0;
    border-radius: 0;
  }
  .product-image .form-control {
    border: 0;
  }
}
