.user_dashboard {
  .page_title {
    @media (max-width: 798px) {
      padding-top: 45px;
    }
    h3 {
      font-size: 28px;
      line-height: 1.3;
      margin-bottom: 15px;
    }
  }

  .dashboard_cards {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0;

    @media (max-width: 991px) {
      flex-direction: column;
    }
    .item {
      max-width: calc(33.33% - 20px);
      width: 100%;
      display: inline-block;
      margin: 15px 15px 0 0;
      padding: 20px;
      border-radius: 10px;
      min-height: 130px;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      transition: all 0.3s linear;

      @media (max-width: 991px) {
        max-width: 100%;
      }

      &:hover {
        transform: scale(1.01);
        box-shadow: rgba(0, 0, 0, 0.15) 0px 5px 15px 0px;
        transition: all 0.4s linear;
      }

      .icon {
        span {
          display: inline-block;
          font-size: 50px;
        }
      }

      .content {
        text-align: center;

        span {
          font-size: 30px;
          font-weight: bold;
          margin-bottom: 5px;
          display: inline-block;
          color: #22d129;
        }

        p {
          font-weight: normal;
          color: #aaa;
        }
      }

      &.new {
        background: rgba(165, 255, 169, 0.25882352941176473);

        span {
          color: #22d129;
        }
      }

      &.total {
        background-color: rgba(33, 127, 243, 0.2);

        span {
          color: #3433be;
        }
      }

      &.saved {
        background-color: rgba(33, 177, 243, 0.2);

        span {
          color: #058fd4;
        }
      }

      a {
        position: absolute;
        width: 100%;
        height: 100%;
        right: 0;
      }
    }
  }
}

////////////////// Reset Password ////////////////////////

.reset_password {
  .recent_order {
    padding: 55px 20px;

    form {
      max-width: 767px;
      width: 100%;
      margin: 0 auto;
      label {
        margin-bottom: 5px;
      }

      input {
        border: 1px solid grey;
        transition: all 0.15s linear;
        margin-bottom: 0;
        min-height: 35px;
        display: block;
        width: 100%;
        padding: 5px 10px;
        font-size: 16px;
        line-height: 1.2;
        border-radius: 0;
      }

      button.btn-black.btn.btn-primary {
        margin-top: 20px;
        font-size: 16px;
        font-weight: 500;
        padding: 8px 15px;
      }
    }
  }
}

//////////////////////////// WishListing Page //////////////////////////@include
.wish_list {
  .item {
    display: flex;
    width: 100%;
    justify-content: space-between;
    padding: 10px;
    box-shadow: 0 1px 3px #ddd;
    margin-bottom: 10px;

    .item_image {
      padding: 30px 0px;
      width: 100%;
      max-width: 165px;
      max-width: 25%;
      img {
        width: 150px;
        height: 150px;
        object-fit: contain;
      }
    }
    .item_name {
      padding: 20px;
      width: 100%;
      max-width: 50%;
      color: #696363;
      a {
        &:hover {
          h6 {
            color: inherit;
          }
        }
      }
      .review {
        display: flex;
        align-items: center;
        > span {
          padding: 0 5px;
        }
      }
    }
    .item_button {
      padding: 20px;
      width: 100%;
      max-width: 25%;
      border-left: 1px solid #ebebeb;
      position: relative;

      .close {
        font-size: inherit;
        position: absolute;
        top: 0;
        right: 0;
        cursor: pointer;
        width: 25px;
        height: 25px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      h5 {
        del {
          color: rgba(231, 50, 50, 0.753);
          padding: 0 10px;
          display: inline-block;
          opacity: 0.6;
        }
      }
      .button_wrap {
        .btn-black {
          padding: 7px 20px;
          font-size: 16px;
          border-style: solid;
          &:hover {
            background-color: #cdaf73;
            border-color: #cdaf73;
            color: #fff;
          }
        }
      }
    }
  }
  .no-product-wrap {
    text-align: center;
    img {
      max-width: 400px;
      margin: 0 auto;
      text-align: center;
      margin-bottom: 20px;
    }
  }
}
