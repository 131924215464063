.blog {
    .blog_listing {
        display: flex;
        justify-content: space-between;
        width: 100%;

        @media (max-width: 991px) {
            flex-wrap: wrap;
        }

        .blog_aside {
            width: 100%;
            max-width: 324px;
            padding: 25px 15px;
            box-shadow: #00000029 0px 10px 36px 0px, #0000000f 0px 0px 0px 1px;
            border-radius: 5px;
            height: 100%;

            @media (max-width: 991px) {
                max-width: 100%;
                order: 2;
                display: flex;
                flex-wrap: wrap;
            }

            form.search {
                width: 100%;
                max-width: 90%;
                border: 1px solid #aaa;
                border-radius: 5px;
                background: #fff;
                position: relative;
                margin: 0 auto;
                margin-bottom: 10px;

                @media (max-width: 991px) {
                    max-width: 65%;
                    margin: 0;
                    margin: 10px 15px;
                }

                @media (max-width: 767px) {
                    max-width: 100%;
                }

                input {
                    border: none;
                    outline: none;
                    padding: 5px 10px;
                    min-height: 30px;
                    background-color: transparent;
                    width: 90%;
                }

                .icon {
                    position: absolute;
                    top: 50%;
                    transform: translateY(-50%);
                    cursor: pointer;
                    padding: 5px;
                    display: inline-block;
                }
            }

            .blog_category {
                @media (max-width: 991px) {
                    width: 100%;
                    padding: 10px 15px;
                    max-width: 48%;
                }

                @media (max-width: 767px) {
                    max-width: 100%;
                    padding: 10px;
                }

                li {
                    a {
                        padding: 5px 10px;
                        display: block;
                    }
                }
            }

            .popular_post {
                @media (max-width: 991px) {
                    width: 100%;
                    padding: 10px 15px;
                    max-width: 48%;
                }

                @media (max-width: 767px) {
                    max-width: 100%;
                    padding: 10px;
                }

                li {
                    margin-bottom: 5px;

                    @media (max-width: 575px) {
                        margin-bottom: 10px;
                    }

                    a {
                        display: flex;
                        width: 100%;
                        padding: 5px 10px;
                        align-items: center;

                        @media (max-width: 575px) {
                            flex-direction: column;
                        }

                        img {
                            max-width: 102px;
                            padding: 5px;

                            @media (max-width: 575px) {
                                max-width: unset;
                            }
                        }

                        p {
                            margin: 0;
                            padding-left: 10px;

                            @media (max-width: 575px) {
                                padding: 7px 0;
                            }
                        }
                    }
                }
            }

            h4 {
                font-size: 18px;
                padding: 10px 0;
                margin: 0;
                font-weight: bold;
            }
        }

        .blog_main {
            width: 100%;
            max-width: calc(100% - 350px);
            padding: 25px 15px;
            box-shadow: #00000029 0px 10px 36px 0px, #0000000f 0px 0px 0px 1px;
            border-radius: 5px;

            @media (max-width: 991px) {
                max-width: 100%;
                order: 1;
                margin-bottom: 20px;
            }

            .blog_card_wrap {
                .blog_card {
                    width: 100%;
                    display: flex;
                    padding: 20px 0;
                    border-bottom: 2px solid #80808054;

                    &:not(:last-child) {}

                    @media (max-width: 767px) {
                        flex-direction: column;
                        align-items: center;
                        text-align: center;
                    }

                    .featured_img {
                        width: 100%;
                        max-width: 300px;
                        padding-right: 10px;
                        height: auto;
                        height: 229px;

                        @media (max-width: 767px) {
                            padding: 10px 0;
                        }

                        img {
                            width: 100%;
                            object-fit: cover;
                            height: 100%;
                            transition: all 0.3s linear;
                        }

                        &:hover {
                            img {
                                transform: scale(1.03);
                            }
                        }
                    }

                    .listing_content {
                        width: 100%;
                        padding-left: 10px;
                        position: relative;

                        @media (max-width: 767px) {
                            padding: 10px 0;
                        }

                        a {
                            &:hover {
                                h3 {
                                    color: #cdaf73;
                                }
                            }
                        }

                        h3 {
                            font-size: 24px;
                        }

                        p {
                            text-align: left;
                        }
                    }

                    .button_wrap {
                        text-align: right;
                        position: absolute;
                        bottom: 0;
                        width: 100%;
                        padding: 0 15px;

                        a {
                            font-size: 16px;
                            font-weight: 500;
                            padding: 5px 10px;
                            background-color: #cdaf73;
                            border-color: #cdaf73;
                            transition: all 0.15s linear;
                            color: #fff;
                            border-radius: 5px;
                            border: none;
                            border-radius: 0;

                            &:hover,
                            &:active {
                                border: none;
                                color: #063553;
                                background-color: #212529;
                                border-color: #212529;
                                color: #fff;
                            }
                        }
                    }
                }
            }
        }
    }
}

.blog_post {
    .h-banner-wrap {
        position: relative;

        .h-banner-inner {
             img {
            
                max-height: 500px;
                object-fit: cover;
            }
            &:after {
               
            }
        }

        .banner_content {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, 50%);
            color: #fff;
            width: 100%;
            display: none;
            top: 0;

            @media (max-width: 767px) {
                top: 30%;
                transform: none;
                right: 0;
                left: 0;
                text-align: center;
            }

            h1 {
                font-size: 50px;
                color: #fff;
                line-height: 1.1;
                text-align: center;
                text-transform: capitalize;

                @media (max-width: 767px) {
                    font-size: 30px;
                }
            }
        }
    }

    .post_content {
        padding: 30px;

        @media (max-width: 575px) {
            padding: 0;
            margin-top: 20px;
        }

        .container {
            display: flex;
            justify-content: space-between;

            @media (max-width: 767px) {
                flex-wrap: wrap;
            }
        }

        h4 {
            font-size: 24px;
            text-transform: capitalize;
            margin-bottom: 15px;
            position: relative;

            &::after {
                content: "";
                position: absolute;
                width: 100%;
                top: 100%;
                right: 0;
                max-width: 27%;
                left: 0;
                border-bottom: 2px solid #063553f5;
            }
        }

        .post_left {
            width: 100%;
            max-width: calc(100% - 320px);
            box-shadow: #00000026 0px 15px 25px,
                #0000000d 0px 5px 10px;
            padding: 20px;

            @media (max-width: 767px) {
                max-width: 100%;
            }

            .socila_section {
                display: flex;
                padding: 15px 0;
                justify-content: space-between;
                align-items: center;
                flex-wrap: wrap;

                p {
                    margin: 10px 0;
                    padding-right: 10px;

                    &.date-view {
                        span {
                            padding-right: 10px;
                        }
                    }
                }
            }

            .share-social {
                span {
                    font-size: 18px;
                    font-weight: 500;
                    color: #063553f5;
                    padding-right: 10px;
                }

                button {
                    padding: 3px !important;

                    svg {
                        height: 40px;
                        width: 40px;
                    }
                }
            }

            .comments {
                margin: 25px 0;
            }

            .contact-form-area {
                h5 {
                    margin-bottom: 10px;
                }

                .contact-form {
                    .btn-primary {
                        font-size: 16px;
                        font-weight: 500;
                        padding: 5px 30px;
                        background-color: #cdaf73;
                        border-color: #cdaf73;
                        transition: all 0.15s linear;
                        color: #fff;
                        border-radius: 5px;
                        border: none;
                        border-radius: 0;

                        &:hover,
                        &:active {
                            border: none;
                            color: #063553;
                            background-color: #212529;
                            border-color: #212529;
                            color: #fff;
                        }
                    }
                }
            }

        }

        .pos-right {
            width: 100%;
            max-width: 325px;
            padding: 20px;

            @media (max-width: 767px) {
                max-width: 100%;
                padding: 0;
                margin-top: 20px;
            }

            .blog_category {
                padding: 20px;
                margin-bottom: 15px;
                box-shadow: #00000026 0px 15px 25px,
                    #0000000d 0px 5px 10px;

                ul {
                    li {
                        width: 100%;
                        margin-bottom: 10px;

                        a {
                            width: 100%;
                            display: flex;
                            justify-content: space-between;
                            color: #063553f5;
                            padding: 5px 5px;
                            font-weight: bold;

                            span {

                                &:last-child {
                                    position: relative;
                                    right: 5px;
                                    transition: all 0.3s linear;
                                }
                            }

                            &:hover {
                                color: #cdaf73;

                                span {

                                    &:last-child {
                                        right: 0;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            .recent_post {
                padding: 20px;
                box-shadow: #00000026 0px 15px 25px,
                    #0000000d 0px 5px 10px;

                .recent_post_conent {
                    padding-top: 10px;
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    margin-bottom: 15px;
                    a>img {
                        width: 100px;
                        height: 100%;
                        object-fit: cover;
                    }

                    .recent_post_conent_body {
                        padding-left: 10px;
                        color: #063553f5;

                        a {
                            color: inherit;

                            h6 {
                                font-size: 14px;
                                color: inherit;
                                font-weight: 500;
                            }

                            &:hover {
                                color: #cdaf73;
                            }
                        }

                        p {
                            padding: 6px 0;
                            color: inherit;
                            margin: 0;
                            display: flex;
                            align-items: center;

                            span {
                                display: inline-block;

                                &:first-child {
                                    padding-right: 5px;
                                }
                            }
                        }


                    }

                }
            }
        }
    }
}