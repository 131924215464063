/** @format */

.vd-dashboard-wrap {
  .cm-autocomplete.brand {
    z-index: 11;
  }
  &.addnew-product {
  }

  .upload-input-wrap {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    .gallery-img-preview {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
      margin-bottom: 35px;
      .gip-img {
        max-height: 100px;
        max-width: 100px;
        margin-right: 20px;
        margin-bottom: 20px;
        border: 1px solid #6a6a6a8f;
        padding: 3px;
        position: relative;
        img {
          object-fit: cover;
          max-width: 100%;
          width: 100%;
          height: 100%;
        }
        .delete-img {
          position: absolute;
          left: -15px;
          top: -15px;
          svg {
            font-size: 28px;
          }
        }
      }
    }
  }

  .upload-input-inner {
    max-width: 150px;
    width: 100%;
    margin-left: 10px;
    margin-bottom: 14px;
  }
  .mb-3.cm-file-upload img {
    width: 160px;
  }
}

.delete-img {
  position: absolute;
  top: 20px;
  left: 7px;
  cursor: pointer;
}

.delete-img svg {
  font-size: 23px;
  color: #ff0000a6;
}



.add-variant-btn #add-variant:checked+ .variantOn {
  display: none;
}

.add-variant-btn #add-variant:checked + .btn-wrap_2 .variantOn {
  display: none;
}

.add-variant-btn #add-variant:checked + .btn-wrap_2 .variantOff {
  display: block;
}

.add-variant-btn #add-variant + .btn-wrap_2 .variantOff {
  display: none;
}

.add-variant-btn #add-variant {
  display: none;
}

.btn-wrap_2 {
  height: 28px;
  width: 70px;
  background-color: #dfdbdb;
    border-radius: 20px;
  cursor: pointer;
}

.btn-toggle {
  width: 30px;
  background-color: #063553;
  height: 100%;
  border-radius: 50%;
  transition: .2s linear;
  opacity: .5;
}

.add-variant-btn #add-variant:checked + .btn-wrap_2 .btn-toggle {
  transform: translateX(131% );
  opacity: 1;
}

.customLabel {
  font-size: 16px;
  font-weight: 500;
}

.button-w {
  text-align: left;
  margin-top: 20px;
  margin-bottom: 20px;
  .btn-brown {
    border-radius: 0;
    border: 0;
    padding: 8px 20px;
    font-size: 14px;
  }
}

