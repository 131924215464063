@import "../../sass/abstracts/mixins";

.categories {
  .adertise_slider {
    padding: 60px 0 40px;

    @media (max-width: 575px) {
      padding: 50px 0 25px;
    }

    .item {
      a {
        display: block;

        img {
          width: 100%;
          max-width: 90%;
          margin: 0 auto;
        }
      }
    }

    .slick-slider {
      .slick-prev {
        top: -22px;
        height: auto;
        left: auto;
        right: 60px;
        display: block !important;
        z-index: 9;

        &:before {
          font-size: 30px;
        }
      }

      .slick-next {
        top: -22px;
        height: auto;
        display: block !important;
        z-index: 9;

        &:before {
          font-size: 30px;
        }
      }
    }
  }

  .categories_content {
    .container {
      display: flex;
      justify-content: space-between;
    }

    .category_aside {
      width: 100%;
      max-width: 260px;
      @include BoxShadow;

      @media (max-width: 991px) {
        position: fixed;
        max-width: 100%;
        top: 100px;
        right: 0;
        left: 0;
        z-index: 9;
        height: 100vh;
        transition: all 0.3s linear;
        transform: translateY(-137%);
        background-color: #fff;
        padding-top: 20px;
      }

      .aside_content {
        .close-b {
          position: relative;
          top: 0;
          text-align: right;
          margin-bottom: 15px;
          margin-right: 5px;
          cursor: pointer;
          font-size: 14px;
          transition: all 0.25s linear;

          &:hover {
            color: #cdaf73;
          }

          @media (min-width: 991.2px) {
            display: none;
          }
        }

        .accordion-item {
          border: 1px solid rgba(0, 0, 0, 0.125);

          .accordion-header {
            font-size: 16px;
            color: #000;
            font-weight: bold;
            text-align: center;
            padding: 7px 10px;
            background-color: #fff;
            font-weight: bold;
            margin: 0;

            button {
              background: transparent;
              width: 100%;
              display: block;
              border: none;
              outline: none;
              font-weight: inherit;
            }
          }

          .accordion-body {
            ul {
              li {
                position: relative;

                &.--show {
                  ul {
                    display: block;
                  }
                }

                a {
                  display: block;
                  padding: 5px 10px;
                  font-weight: bold;
                  font-size: 15px;
                }

                span {
                  position: absolute;
                  right: 0;
                  padding: 5px;
                  z-index: 9;
                  top: 1.5px;
                  cursor: pointer;
                }

                &:hover {
                  color: #cdaf73;

                  a {
                    color: inherit;
                  }
                }

                ul {
                  margin-top: 15px;
                  border-top: 1px solid #aaa;
                  display: none;

                  li {
                    a {
                      padding: 5px 10px;
                      font-weight: normal;
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }
        }

        .slide_range {
          display: block;
          // margin: 20px 0;
          text-align: center;
          border: 1px solid;

          &.btn {
            border-radius: 0;
          }

          p {
            margin: 0;
            padding: 7px 0;
          }
        }

        .range_slider {
          padding: 20px 0 0 0;

          .noUi-pips-horizontal {
            display: none;
          }

          h3 {
            font-size: 16px;
            color: #000;
            text-align: center;
            padding: 7px 10px;
            background-color: #fff;
            font-weight: bold;
            margin: 0;
          }

          .slide_range {
            display: block;
            margin: 20px 0;
            text-align: center;
            border: 1px solid;

            &.btn {
              border-radius: 0;
            }

            p {
              margin: 0;
              padding: 7px 0;
            }
          }
        }
      }
    }

    .category_main {
      width: 100%;
      max-width: calc(100% - 275px);
      @include BoxShadow;

      @media (max-width: 991px) {
        max-width: 100%;
      }

      @media (max-width: 575px) {
        box-shadow: none;
        padding-left: 0;
        padding-right: 0;
      }

      .top_bar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: 5px 20px;
        border-bottom: 1px solid #aaa;
        margin-bottom: 20px;

        @media (max-width: 767px) {
          flex-direction: column;
        }

        @media (max-width: 575px) {
          padding: 0;
        }

        .all_categories {
          width: 100%;
          max-width: 142px;
          cursor: pointer;

          @media (min-width: 992px) {
            display: none;
          }

          @media (max-width: 767px) {
            max-width: 100%;
            text-align: left;
          }

          span {
            &:first-child {
              padding: 10px;
              font-size: 20px;
            }
          }
        }

        .content_left {
          display: flex;
          align-items: center;
          width: 100%;

          span {
            padding: 10px;
            font-size: 20px;
            cursor: pointer;
          }

          .toggle-view {
            padding: 10px;
            font-size: 12px;
            cursor: pointer;
          }

          p {
            margin: 0;
            padding: 0 15px;
          }
        }

        .content_right {
          width: 100%;
          text-align: right;

          @media (max-width: 991px) {
            max-width: 200px;
          }

          @media (max-width: 767px) {
            max-width: 100%;
            text-align: left;
            padding: 10px 10px;
          }

          select {
            max-width: 200px;
            width: 100%;
            min-height: 28px;
            outline: none;
            border: 1px solid #aaa;
          }
        }
      }

      .product_card_wrap {
        margin-top: 10px;
        position: relative;

        .product_card {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;

          @media (max-width: 767px) {
            justify-content: center;
          }

          .card_content {
            margin: 10px 10px;
            text-align: left;
            width: 100%;
            max-width: 200px;
            // box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px,
            //   rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
            flex-wrap: wrap;
            align-items: end;
            display: flex;
            overflow: hidden;
            max-width: 30%;
            padding: 10px;
            position: relative;

            a {
              width: 100%;
              text-align: center;
            }

            img {
              max-width: 180px;
              width: 100%;
              max-height: 158px;
              height: 100%;
              object-fit: contain;
              margin: 0 auto;
            }

            .out-of-stock {
              position: absolute;
              top: 46px;
              left: -34px;
              transform: rotate(270deg);
            }

            .out-of-stock {
              background-color: #f60000ba;
              border-color: #f60000ba;
              color: #fff;
              padding: 3px 8px;
              z-index: 9;
              font-weight: 500;
            }

            @media (max-width: 700px) {
              max-width: 45%;
            }

            @media (max-width: 480px) {
              max-width: 270px;
            }

            .img_wrap {
              width: 100%;
              height: 135px;
              overflow: hidden;

              a {
                width: 100%;
                height: 100%;

                img {
                  width: 100%;
                  max-width: 100%;
                  transition: all 0.3s linear;
                  margin: 0 auto;
                  display: block;
                  object-fit: contain;
                  height: 100%;
                }
              }
            }

            .pc-content {
              padding: 10px;
              width: 100%;
              text-align: center;

              a {
                display: block;
                text-align: center;

                h3 {
                  font-size: 14px;
                  line-height: 1.3;
                  padding: 1px 0;
                  margin-top: 14px;
                  font-weight: bold;
                  text-align: center;
                }
              }

              .pc-review {
                // display: flex;
                align-items: center;
                justify-content: center;
              }

              P {
                margin: 0;

                span {
                  padding: 5px;
                  display: inline-block;

                  del {
                    opacity: 0.6;
                    color: red;
                  }

                  &:first-child {
                    font-weight: bold;
                    font-size: 16px;
                  }

                  svg {
                    position: relative;
                    top: -2px;
                  }
                }
              }
            }

            &:hover {
              img {
                transform: scale(1.04);
              }
            }

            .button {
              width: 100%;
              text-align: center;

              button {
                font-size: 20px;

                &:hover {
                  color: #1a4766;
                }
              }
            }
          }

          &.list-view {
            .card_content {
              max-width: unset;
              display: flex;
              flex-wrap: nowrap;
              align-items: flex-start;
              min-height: 180px;
              overflow: hidden;
              align-items: center;
              text-align: center;

              @media (max-width: 575px) {
                min-height: 150px;
                min-height: auto;
                padding: 10px;
                flex-wrap: wrap;
                justify-content: center;
              }

              .img_wrap {
                width: 100%;
                max-width: 190px;
                padding: 10px;

                @media (max-width: 575px) {
                  max-width: 200px;
                }

                img {
                  max-width: 100%;
                  object-fit: contain;

                  // @media (max-width: 575px) {
                  //   width: 110px;
                  //   height: 110px;
                  //   padding: 0;

                  // }

                  // @media (max-width: 575px) {
                  //   width: 90px;
                  //   height: 90px;
                  // }
                }
              }

              .pc-content {
                width: calc(100% - 220px);
                text-align: left;

                @media (max-width: 575px) {
                  width: 100%;
                  padding-top: 0;
                  padding-bottom: 0;
                  text-align: center;
                  padding: 10px 0;
                }

                a {
                  text-align: left;

                  @media (max-width: 575px) {
                    text-align: left;
                  }

                  h3 {
                    font-size: 16px;
                    margin-top: 0;
                    padding-top: 0;
                    padding-bottom: 0;
                    text-align: left;
                  }
                }

                .pc-review {
                  justify-content: flex-start;
                }
              }

              .button {
                width: auto;

                @media (max-width: 575px) {
                  width: 100%;
                  text-align: center;
                }
              }
            }
          }
        }
      }

      .pcw-pagination {
        margin-top: 50px;

        .page-item {
          .page-link {
            border: none;
            border-radius: 50%;
          }

          &.active {
            .page-link {
              background-color: #cdaf73;
            }
          }

          &.disabled {
            opacity: 0.6;
          }
        }
      }
    }
  }

  &.slide {
    .categories_content {
      .category_aside {
        transform: translateY(0);
      }
    }
  }
}

.scroll .categories.slide .categories_content .category_aside {
  top: 90px;
}

.categories
  .categories_content
  .category_main
  .top_bar
  .content_right
  select.count {
  width: auto;
  max-width: unset;
  margin-right: 8px;
}

.country img {
  width: 25px;
  height: 20px;
  border-radius: 40%;
}

.country select {
  appearance: none;
  background-color: transparent;
  border: none;
  padding: 0 1em 0 0;
  margin: 0;
  width: 100%;
  font-family: inherit;
  font-size: inherit;
  cursor: inherit;
  line-height: inherit;
  cursor: pointer;
  outline: none;
  font-size: 16px;
}

.country {
  display: flex;
  width: 100%;
  align-items: center;
}

.country select::-ms-expand {
  display: none;
}

.country .img {
  margin-right: 8px;
}

.country select option {
  padding: 8px;
}

.country .l-loc span {
  font-size: 25px;
  margin-right: 5px;
}

.categories .h-banner-inner {
  min-height: 160px;
}
