/** @format */

@import "../../sass/abstracts/_Variable.scss";
@import "../../sass/abstracts/_mixins.scss";
.h-banner-wrap {
  position: relative;
}
.h-banner-inner {
  width: 100%;
  position: relative;
  min-height: 280px;
  background-color: #1a4766;

  // &:after {
  //     content: '';
  //     position: absolute;
  //     height: 100%;
  //     width: 100%;
  //     left: 0;
  //     top: 0;
  //     background-color: #0303036e;
  // }
  & img {
    width: 100%;
    object-fit: cover;
    min-height: 280px;
    max-height: 500px;
  }
}
.banner_content {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  .container {
    h1 {
      text-align: center;
      color: #fff;
      margin-bottom: 50px;
      @media(max-width: 768px) {
        font-size: 30px;
        margin-bottom: 25px;
      }
    }
  }
}
