.not-found-inner {
    text-align: center;
    padding: 7rem 0;
    p {
        font-size: 30px;
    }
    h1 {
        line-height: 1.2;
        margin-bottom: 0;
    }
}


