*{
    margin: 0;
    padding:0;
    box-sizing: border-box;
}
html {
    scroll-behavior: smooth;
  }
  
  
 
img{
    max-width: 100%;
    vertical-align: middle;
}

ol, ul {
	list-style: none;
}

a {
    -webkit-transition: 0.15s ease-out;
    -moz-transition: 0.15s ease-out;
    -o-transition: 0.15s ease-out;
    transition: 0.15s ease-out;
  }