@import "../../sass/abstracts/_Variable.scss";
p {
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
  line-height: inherit;
}

section.advertising-wrap {
  padding-bottom: 10px;
  max-width: 1240px;
  margin: 0 auto;
  padding-top: 15px;
  text-align: center;
  @media (max-width: 767px) {
    padding-bottom: 10px;
    padding-top: 40px;
  }
  @media (max-width: 460px) {
    padding-bottom: 10px;
    padding-top: 30px;
  }
  .slider-heading-wrap h2 {
    font-size: 30px;
    line-height: 1.57;
    margin-bottom: 20px;
    text-transform: uppercase;
    @media (max-width: 991px) {
      font-size: 25px;
    }
    @media (max-width: 767px) {
      font-size: 20px;
    }
   
  }
  .slider-img-wrap {
    img {
      object-fit: cover;
      display: inline-block;
      width: 100%;
      padding: 0 10px;
      max-height: 260px;

      &:focus-visible {
        outline: none;
      }
    }
  }
}

.top-country-wrap {
  padding-bottom: 60px;
  padding-top: 60px;
  text-align: center;
  overflow-x: hidden;
  .slider-heading-wrap h2 {
    font-size: 30px;
    line-height: 1.57;
    margin-bottom: 20px;
    text-transform: uppercase;
    @media (max-width: 991px) {
      font-size: 25px;
    }
    @media (max-width: 767px) {
      font-size: 20px;
    }
    @media(max-width:640px){
      margin-bottom: 0px;
    }
  }
}

.best-selling-wrap {
  padding-bottom: 60px;
  padding-top: 60px;
  text-align: center;
  overflow-x: hidden;
  background-color: #eff7fa;
  .cm-container {
    padding: 0 40px;
    @media (max-width: 640px) {
      padding: 0 15px;
    }
  }
}

.video-banner {
  // padding-bottom: 60px;
  // padding-top: 60px;
  text-align: center;
  overflow-x: hidden;
}

section.blog-wrap {
  padding-bottom: 60px;
  padding-top: 60px;
  text-align: center;
  overflow-x: hidden;
  .cm-container {
    padding: 0 35px;
    @media (max-width: 640px) {
      padding: 0 15px;
    }
  }
}

.home-page-main-wrap {
  .slick-next {
    right: 10px;
    top: auto;
    bottom: -40px;
  }

  .slick-prev {
    left: auto;
    right: 54px;
    bottom: -40px;
    top: auto;
  }

  .slick-next:before {
    color: #cdaf73;
    font-size: 40px;
    transition: all 0.2s linear;
  }

  .slick-prev:before {
    color: #cdaf73;
    font-size: 40px;
    transition: all 0.2s linear;
  }

  .slick-prev,
  .slick-next {
    width: 40px;
    z-index: 9;
  }

  .slick-next:hover {
    background-image: linear-gradient(to right, #efefef2e, #ffffff9e);
  }

  .slick-prev:hover {
    background-image: linear-gradient(to left, #efefef2e, #ffffff9e);
  }

  .slick-prev,
  .slick-next {
    transition: all 0.2s linear;
    @media(max-width:640px){
      display: none!important;
    }
  }

  .slick-dots {
    bottom: -40px;
  }

  .slick-dots li button:before {
    color: #c9ac72;
    opacity: 0.6;
    font-size: 8px;
  }
  .slick-dots li.slick-active button:before {
    color: #c9ac72;
    opacity: 1;
    font-size: 8px;
  }
  .slick-dots li {
    width: 5px;
  }
  @media screen and (max-width: 1200px) {
    .slick-dots li button:before {
      color: #c9ac72;
      opacity: 0.6;
      font-size: 5px;
    }
    .slick-dots li.slick-active button:before {
      color: #c9ac72;
      opacity: 1;
      font-size: 8px;
    }
  }
}

video {
  width: 100%;
  max-height: 600px;
  object-fit: cover;
}


.home-page-main-wrap .home-slider .slick-dots {
  bottom: 14px;
}

.home-slider .slider-img-wrap {
  background-color: #0f5275;
  max-height: 550px;
  // min-height: 300px;
}

ul.top.categories li {
  font-size: 15px;
  font-weight: 500;
  padding-right: 10px;
  padding-left: 0;
  display: inline-block;
}

ul.top.categories {
  width: 100%;
  margin: 0;
  padding: 15px 0;
  flex-wrap: wrap;
  list-style: none;
  display: block;
  border-top: 1px solid;
  margin-top: 70px;
}
.footer_links {
  width: 100%;
}


.home-slider .slick-slide img{
  width: 100%;
}