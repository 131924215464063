.alert-wrap {
    position: fixed;
    top: 30px;
    padding-left: 20px;
    padding-right: 20px;
    z-index: 9999;
    width: 100%;
    height: 100%;
    p{
        margin: 0;
        padding: 0;
    }
}

