.vender_store {
  .banner {
    .image {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center;
      min-height: 200px;
      padding-bottom: 20%;
      width: 100%;
      position: relative;
      background-color: #06355336;

      a {
        position: absolute;
        width: 100%;
        right: 0;
        left: 0;
        height: 100%;
      }
    }
  }

  .card_wrap {
    padding: 30px 0;
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-around;

    .card_content {
      margin: 10px 10px;
      text-align: left;
      width: 100%;
      max-width: 200px;
      // box-shadow: rgba(0, 0, 0, 0.2) 0px 1px 3px 0px,
      //   rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
      flex-wrap: wrap;
      align-items: end;
      display: flex;
      overflow: hidden;
      max-width: 250px;
      padding: 10px;

      // @media (max-width: 700px) {
      //     max-width: 45%;
      // }

      @media (max-width: 480px) {
        max-width: 270px;
      }

      .img_wrap {
        width: 100%;
        height: 135px;
        overflow: hidden;

        a {
          width: 100%;
          height: 100%;

          img {
            width: 100%;
            max-width: 100%;
            transition: all 0.3s linear;
            margin: 0 auto;
            display: block;
            object-fit: contain;
            height: 100%;
          }
        }
      }

      .pc-content {
        padding: 10px;
        width: 100%;
        text-align: center;

        a {
          display: block;
          text-align: center;

          h3 {
            font-size: 14px;
            line-height: 1.3;
            padding: 1px 0;
            margin-top: 14px;
            font-weight: bold;
          }
        }

        .pc-review {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        P {
          margin: 0;

          span {
            padding: 5px;
            display: inline-block;

            del {
              opacity: 0.6;
              color: red;
            }

            &:first-child {
              font-weight: bold;
              font-size: 16px;
            }

            svg {
              position: relative;
              top: -2px;
            }
          }
        }
      }

      &:hover {
        img {
          transform: scale(1.04);
        }
      }

      .button {
        width: 100%;
        text-align: center;

        button {
          font-size: 20px;

          &:hover {
            color: #1a4766;
          }
        }
      }

      &:last-child {
        margin-right: auto;
      }
    }
  }

  .view-all-wrap {
    margin-top: 15px;

    .view-all-wrap-inner {
      text-align: center;
    }

    .btn-black {
      padding: 8px 25px;
    }
  }

  .no-product-found {
    text-align: center;
    margin-top: 70px;
  }

  .page-item.disabled {
    opacity: 0.6;
  }

  .page-link {
    border: none;
    border-radius: 50%;
  }

  .page-item.active .page-link {
    background-color: #cdaf73;
  }
}